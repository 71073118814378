import './portal.style.scss';
import PlanningGirl from '../../images/pixeltrue-plan.svg';
import PortalButtonComponent from '../assets/portal-button.component';
/** REDUX functions */
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { H2Title } from '../../theming/customTheme';

import ManagerPortalCongesComponent from './manager/manager-portal-conges.component';
import ManagerPortalNDFComponent from './manager/manager-portal-ndf.component';
import { Paid, BeachAccess, Task, Group, UploadFile, CalendarMonth } from '@mui/icons-material';

import './portal.style.scss';

const ManagerPortalComponent = (props) => {
    const [displayMenuModalState, setDisplayMenuModalState] = useState(false);
    const [displayMenuModalNDFState, setDisplayMenuModalNDFState] = useState(false);
    const handleToggleMenuModal = () => {
        if (displayMenuModalNDFState) {
            setDisplayMenuModalState(!displayMenuModalState);
            setDisplayMenuModalNDFState(!displayMenuModalNDFState);
        } else {
            setDisplayMenuModalState(!displayMenuModalState);
        }
    };
    const handleToggleMenuModalNDF = () => {
        if (displayMenuModalState) {
            setDisplayMenuModalState(!displayMenuModalState);
            setDisplayMenuModalNDFState(!displayMenuModalNDFState);
        } else {
            setDisplayMenuModalNDFState(!displayMenuModalNDFState);
        }
    };
    const handleDisplayMenuModalNDF = () => {
        return displayMenuModalNDFState && <ManagerPortalNDFComponent role={'manager'} />;
    };
    const handleDisplayMenuModal = () => {
        return displayMenuModalState && <ManagerPortalCongesComponent role={'manager'} />;
    };

    useEffect(() => {
        props.onLoad('Manager - Mon portail');
    }, []);

    return (
        <Box sx={{ p: 1 }}>
            <H2Title>Mes Liens</H2Title>
            <Grid container spacing={2}>
                <PortalButtonComponent
                    title="Mon Equipe"
                    icon={Group}
                    color="primary.main"
                    link="manager/team"
                    role={'manager'}
                />
                <PortalButtonComponent
                    title="Planning d'équipe"
                    icon={CalendarMonth}
                    color="success.main"
                    link="planning-manager"
                    role={'commerce'}
                />
                <PortalButtonComponent
                    title="Gérer les projets"
                    icon={Task}
                    color="secondary.main"
                    link="projects"
                    role={'commerce'}
                />
                <PortalButtonComponent
                    title="Générer un reporting"
                    icon={UploadFile}
                    color="error.main"
                    link="reporting"
                    role={'manager'}
                />
                <PortalButtonComponent
                    title="Gestion des congés"
                    icon={BeachAccess}
                    color="secondary.light"
                    functionButton={1}
                    toggleFunction={handleToggleMenuModal}
                    role={'manager'}
                />
                <PortalButtonComponent
                    title="Gestion des notes de frais"
                    icon={Paid}
                    color="secondary.dark"
                    functionButton={1}
                    toggleFunction={handleToggleMenuModalNDF}
                    role={'manager'}
                />
            </Grid>
            {handleDisplayMenuModal()}
            {handleDisplayMenuModalNDF()}
            <div className="background-decoration-svg">
                <img src={PlanningGirl} height="550px" width="800px" alt="" />
            </div>
        </Box>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedManagerPortalComponent = connect(
    null,
    mapDispatchToProps,
)(ManagerPortalComponent);
