import axiosConfig from './axiosConfig';

const API_URL = '/authorization/user/';

class UserService {
    /**
     * Retourne la liste des tous les utilisateurs en base de données
     */
    getAllUsers() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    sendUserCustomization(objectCustom) {
        return axiosConfig
            .patch(API_URL + 'customization', objectCustom)
            .then((res) => {
                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        ...JSON.parse(localStorage.getItem('user')),
                        ...objectCustom,
                    }),
                );
                return res.data;
            })
            .catch((err) => ({ error: err.message }));
    }

    getWidgets(userId = null) {
        return axiosConfig
            .get(API_URL + 'widgets', { params: { utilisateursId: userId } })
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    updateUserFromObject(objectUser) {
        return axiosConfig
            .patch(API_URL + 'fromObject', objectUser)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    /**
     * Retourne une liste de tout les utilisateurs que l'utilisateur courant à le droit de voir
     */
    getAllUsersAccessible() {
        return axiosConfig
            .get(API_URL + 'accessible')
            .then((response) => response.data)
            .catch((err) => ({
                error: err.message,
            }));
    }

    /**
     * Permet d'update les données d'un utilisateur si l'on a les droits administrateurs
     */
    updateOneUser(
        userId,
        nom,
        prenom,
        email,
        // password,
        estInterne,
        role,
        fonction,
        manager,
        bu,
        solde,
        commentaireSolde,
    ) {
        estInterne = estInterne ? 1 : 0;
        return axiosConfig
            .put(API_URL, {
                userId: userId,
                nom: nom,
                prenom: prenom,
                email: email,
                // password: password,
                estInterne: estInterne,
                role: role,
                fonction: fonction,
                manager: manager,
                bu: bu,
                solde: solde,
                commentaireSolde: commentaireSolde,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /**
     * Retourne l'équipe d'Utilisateurs concernés par un manager précis (via manager_email).
     * @param {Number} manager_email - email du manager dont on veut l'équipe
     */
    getManagedTeam(managerEmail) {
        return axiosConfig
            .post(API_URL + 'managed-team', {
                manager_email: managerEmail,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    /**
     * Retourne l'utilisateur concerné et toutes les infos qui gravitent à son propos
     * @param {String} userEmail - email de l'utilisateur à requêter
     */
    getUserInfos(userEmail) {
        return axiosConfig
            .get(API_URL + 'account', {
                params: {
                    email: userEmail,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getMinimumUserInfos(userEmail) {
        return axiosConfig
            .get(API_URL + 'minimum-infos', { params: { email: userEmail } })
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    /**
     * Retourne l'ensemble des utilisateurs pour un role par entité du token
     * @param {Array} roles - Liste des roles à requêter
     * @returns
     */
    getUsersByRole(role) {
        return axiosConfig
            .get(`${API_URL}by/role`, { params: { role } })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     * Modifie en base le mot de passe de l'utilisateur
     * @param {String} oldPassword - mot de passe actuel
     * @param {String} nPassword - nouveau mot de passe
     */
    updatePassword(userEmail, oldPassword, nPassword) {
        return axiosConfig
            .post(API_URL + 'update-password', {
                userEmail: userEmail,
                password: oldPassword,
                newPassword: nPassword,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    deleteUser(userId) {
        return axiosConfig
            .delete(`${API_URL}${userId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    restore(userId) {
        return axiosConfig
            .get(`${API_URL}restore/${userId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getDeletedUsers() {
        return axiosConfig
            .get(`${API_URL}deleted`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    changeNotificationSettings(objectNotif) {
        return axiosConfig
            .post(API_URL + 'updateNotifications', objectNotif)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    getUserById(userId) {
        return axiosConfig
            .get(`${API_URL}/${userId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
}

export default new UserService();
