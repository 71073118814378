import { useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { toast } from 'react-toastify';
import fichiersService from '../../../services/fichiers.service';

const FileUploadComponent = ({
    periodeDate,
    username,
    onFileUpload,
    onFileDelete,
    formatsAutorises = ['image/png', 'image/jpeg', 'application/pdf'],
    tailleMaxParFichier = 3 * 1024 * 1024,
    nbFichierMax = 5,
    buttonLabel = 'Ajouter un fichier',
    uploadedFiles = [],
    isLoading = false,
    modeVisualisation = false,
}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileUrls, setFileUrls] = useState({});
    const [previewUrls, setPreviewUrls] = useState({});

    useEffect(() => {
        const fetchFileUrls = async () => {
            const urls = {};
            for (const file of uploadedFiles) {
                const url = await fichiersService.getFichierNDF(
                    username,
                    file.fichier,
                    periodeDate,
                );
                urls[file.fichiers_id] = url;
            }
            setFileUrls(urls);
        };
        fetchFileUrls();
    }, [uploadedFiles]);

    useEffect(() => {
        const previews = {};
        selectedFiles.forEach((file, index) => {
            previews[index] = URL.createObjectURL(file);
        });
        setPreviewUrls(previews);
        return () => {
            Object.values(previews).forEach(URL.revokeObjectURL);
        };
    }, [selectedFiles]);

    const handleFileChange = (event) => {
        if (modeVisualisation) return; // Désactiver l'ajout de fichiers en mode visualisation

        const newFiles = Array.from(event.target.files);
        const totalCurrentFiles = selectedFiles.length + uploadedFiles.length;
        const placesRestantes = nbFichierMax - totalCurrentFiles;

        if (totalCurrentFiles + newFiles.length > nbFichierMax) {
            toast.error(`Vous ne pouvez pas ajouter plus de ${nbFichierMax} fichiers`);
            return;
        }

        const validFiles = newFiles
            .filter((file) => {
                if (!formatsAutorises.includes(file.type)) {
                    toast.error(`Type non autorisé : ${file.name}`);
                    return false;
                }
                if (file.size > tailleMaxParFichier) {
                    toast.error(`${file.name} dépasse ${tailleMaxParFichier / 1024 / 1024} Mo`);
                    return false;
                }
                return true;
            })
            .slice(0, placesRestantes);

        setSelectedFiles((prev) => [...prev, ...validFiles]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (modeVisualisation) return; // Désactiver l'envoi de fichiers en mode visualisation

        if (selectedFiles.length === 0) {
            toast.error('Sélectionnez au moins un fichier');
            return;
        }
        try {
            await onFileUpload(selectedFiles);
            setSelectedFiles([]);
        } catch (error) {
            toast.error(`Erreur d'upload : ${error.message}`);
        }
    };

    const removeFile = (index) => {
        if (modeVisualisation) return; // Désactiver la suppression de fichiers en mode visualisation
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const cotasAtteint = selectedFiles.length + uploadedFiles.length >= nbFichierMax;

    return (
        <Box
            sx={{
                mt: 2,
                p: 2,
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: 2,
                maxWidth: 400,
                minWidth: 400,
                width: '100%',
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: 4,
                },
            }}
        >
            <form onSubmit={handleSubmit}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    {selectedFiles.length + uploadedFiles.length} Justificatif(s)
                </Typography>
                {!modeVisualisation && (
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {nbFichierMax - (selectedFiles.length + uploadedFiles.length)} restant(s)
                    </Typography>
                )}

                <Box
                    sx={{
                        mt: 2,
                        p: 2,
                        bgcolor: '#f4f4f9',
                        borderRadius: 2,
                        maxHeight: 300,
                        overflowY: 'auto',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#e0e0eb',
                        },
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                    }}
                >
                    {[...uploadedFiles, ...selectedFiles].map((file, index) => {
                        const isUploaded = index < uploadedFiles.length;
                        const fileName = isUploaded ? file.fichier : file.name;
                        const extension = fileName.split('.').pop().toLowerCase();
                        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
                        const fileUrl = isUploaded
                            ? fileUrls[file.fichiers_id]
                            : previewUrls[index - uploadedFiles.length];

                        return (
                            <Box
                                key={isUploaded ? file.fichiers_id : `selected-${index}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: 1,
                                    borderRadius: 1,
                                    bgcolor: '#fff',
                                    mb: 1,
                                    gap: 1,
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#f0f0f5',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexGrow: 1,
                                        minWidth: 0,
                                        overflow: 'hidden',
                                        flexBasis: '70%',
                                    }}
                                >
                                    <a
                                        href={fileUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        {isImage ? (
                                            <img
                                                src={fileUrl}
                                                alt={fileName}
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    objectFit: 'cover',
                                                    marginRight: 8,
                                                    borderRadius: 4,
                                                }}
                                            />
                                        ) : (
                                            <InsertDriveFileIcon
                                                sx={{ color: 'grey.600', mr: 1, flexShrink: 0 }}
                                            />
                                        )}
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {fileName}
                                        </Typography>
                                    </a>
                                    {isUploaded && (
                                        <CheckCircleIcon
                                            sx={{ color: 'success.main', fontSize: 16, ml: 1 }}
                                        />
                                    )}
                                </Box>
                                {!modeVisualisation && (
                                    <CloseIcon
                                        fontSize="small"
                                        onClick={() =>
                                            isUploaded
                                                ? onFileDelete(file.fichiers_id)
                                                : removeFile(index - uploadedFiles.length)
                                        }
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'error.main',
                                            flexShrink: 0,
                                            opacity: isLoading ? 0.5 : 1,
                                            transition: 'opacity 0.3s ease',
                                        }}
                                        disabled={isLoading}
                                    />
                                )}
                            </Box>
                        );
                    })}
                    {!modeVisualisation && (
                        <Button
                            component="label"
                            color="success"
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                            fullWidth
                            sx={{ mt: 2, borderRadius: 2 }}
                            disabled={isLoading || cotasAtteint}
                        >
                            {buttonLabel}
                            <input
                                type="file"
                                hidden
                                multiple
                                onChange={handleFileChange}
                                accept={formatsAutorises
                                    .map((t) => `.${t.split('/')[1]}`)
                                    .join(',')}
                            />
                        </Button>
                    )}
                </Box>
                {!modeVisualisation && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, borderRadius: 2 }}
                        disabled={selectedFiles.length === 0 || isLoading}
                    >
                        Envoyer ({selectedFiles.length} fichier(s))
                    </Button>
                )}
            </form>
        </Box>
    );
};

export default FileUploadComponent;
