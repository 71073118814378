import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import {
    Container,
    Typography,
    Card,
    CardContent,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import noteDeFraisService from '../../../../services/note_de_frais.service';
import { H2Lighter } from '../../../../theming/customTheme';

const ManageTva = ({ onLoad }) => {
    const [tvaList, setTvaList] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [valeurTva, setValeurTva] = useState({ valeur: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [action, setAction] = useState(null);

    useEffect(() => {
        onLoad('Manager - Gestion de la TVA');
        fetchTvaByEntity();
    }, [onLoad]);

    const fetchTvaByEntity = async () => {
        try {
            const response = await noteDeFraisService.getAllTVA();
            setTvaList(response);
        } catch (error) {
            toast.error('Erreur lors du chargement des TVA');
        }
    };

    const handleOpenDialog = (tva = null) => {
        if (tva) {
            setValeurTva(tva);
            setIsEditing(true);
        } else {
            setValeurTva({ valeur: '' });
            setIsEditing(false);
        }
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setValeurTva({ valeur: '' });
        setIsEditing(false);
        setAction(null);
    };

    const handleSave = async () => {
        try {
            if (isEditing) {
                await noteDeFraisService.updateTva(valeurTva);
                toast.success('TVA mise à jour avec succès');
            } else {
                await noteDeFraisService.createTva(valeurTva);
                toast.success('TVA créée avec succès');
            }
            fetchTvaByEntity();
            handleCloseDialog();
        } catch (error) {
            toast.error('Erreur lors de la sauvegarde de la TVA');
        }
    };

    const handleDelete = (id) => {
        setAction(() => () => deleteConfirmed(id));
        setConfirmOpen(true);
    };

    const deleteConfirmed = async (id) => {
        try {
            await noteDeFraisService.deleteTva(id);
            toast.success('TVA supprimée avec succès');
            setTvaList(tvaList.filter((t) => t.tva_id !== id));
            setConfirmOpen(false);
        } catch (error) {
            toast.error('Erreur lors de la suppression de la TVA');
        }
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
        setAction(null);
    };

    const handleConfirmAction = () => {
        if (action) {
            action();
        }
    };

    return (
        <Container>
            <H2Lighter>Liste des TVA</H2Lighter>
            <Grid container spacing={3}>
                {tvaList.map((tva) => (
                    <Grid item key={tva.tva_id} xs={12} sm={6} md={4}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h6">Taux: {tva.valeur}%</Typography>
                            </CardContent>
                            <CardContent sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton onClick={() => handleOpenDialog(tva)}>
                                    <EditIcon color="primary" />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(tva.tva_id)}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenDialog()}
                        sx={{ width: '90%', height: '50%', fontSize: '1rem', padding: '20px' }}
                    >
                        Ajouter +
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>
                    {isEditing ? 'Modifier la TVA' : 'Ajouter une TVA'}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: 'grey' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Valeur"
                        type="number"
                        value={valeurTva.valeur}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 5) {
                                setValeurTva({ ...valeurTva, valeur: value });
                            }
                        }}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            onWheel: (e) => e.target.blur(),
                        }}
                        inputProps={{
                            maxLength: 4,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmOpen}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">Confirmer l'action</DialogTitle>
                <DialogContent>
                    <Typography>Êtes-vous sûr de vouloir supprimer cette TVA ?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmAction} color="error" variant="contained">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(setPageTitle('Manager - Gestion de la TVA')),
});

export const ConnectedManageTva = connect(null, mapDispatchToProps)(ManageTva);
