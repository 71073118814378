import { React, useEffect } from 'react';
import PortalButtonComponent from '../assets/portal-button.component';
import MonneyGirl from '../../images/pixeltrue-note-de-frais.svg';
// import SpaceSVG from '../../images/space-discovery.svg';
// redux
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { Grid, Box } from '@mui/material';
import { H2Title } from '../../theming/customTheme';
// import NoteDeFraisService from '../../services/note_de_frais.service';

// import VisibilityIcon from '@mui/icons-material/Visibility';

// import { Button, CircularProgress, Grid, Paper } from '@mui/material';
// import CrudPageComponent from '../assets/page/crud-page.component';
// import { Link } from 'react-router-dom';
// import { displayStatusIcon } from '../../helpers/conge.helper';
// import ErrorWarningComponent from '../assets/infos/error-warning.component';
// import SimpleModalComponent from '../assets/modal/simple-modal.component';
// import ListLogSoldesComponent from '../assets/list/list-log-soldes.component';

const NoteDeFraisComponent = (props) => {
    // const [displayMenuModalState, setDisplayMenuModalState] = useState(false);
    // const handleToggleMenuModal = () => {
    //     setDisplayMenuModalState(!displayMenuModalState);
    // };
    // const handleDisplayMenuModal = () => {
    //     return displayMenuModalState;
    // };
    useEffect(() => {
        props.onLoad('Note de frais');
    }, []);

    return (
        <Box sx={{ p: 1 }}>
            <H2Title>Mes Liens</H2Title>
            <Grid container spacing={2}>
                <PortalButtonComponent
                    title="Créer une note de frais"
                    // icon={GroupIcon}
                    color="success.main"
                    link="note-de-frais/check-periode"
                    role={'employe'}
                />
                <PortalButtonComponent
                    title="Brouillon"
                    // icon={TaskIcon}
                    color="secondary.main"
                    link="note-de-frais/mes-brouillons"
                    role={'employe'}
                />
                <PortalButtonComponent
                    title="Mes notes de frais"
                    // icon={CalendarMonthIcon}
                    color="primary.main"
                    link="notes-de-frais"
                    role={'employe'}
                />
                {/* <PortalButtonComponent
                    title="Générer un reporting"
                    // icon={UploadFileIcon}
                    color="error.main"
                    link="reporting"
                    role={'manager'}
                />
                <PortalButtonComponent
                    title="Gestion des congés"
                    // icon={BeachAccessIcon}
                    color="secondary.light"
                    functionButton={1}
                    toggleFunction={handleToggleMenuModal}
                    role={'manager'}
                /> */}
            </Grid>
            {/* {handleDisplayMenuModal()} */}
            <div className="background-decoration-svg">
                <img src={MonneyGirl} height="550px" width="800px" alt="" />
            </div>
        </Box>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedNoteDeFraisMenu = connect(null, mapDispatchToProps)(NoteDeFraisComponent);
