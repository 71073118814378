import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment/moment';

const styles = StyleSheet.create({
    page: {
        padding: 40,
        fontSize: 10,
        fontFamily: 'Helvetica',
    },
    header: {
        marginBottom: 30,
    },
    headerContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    logo: {
        width: 80,
        height: 80,
    },
    headerRight: {
        alignItems: 'flex-end',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    reference: {
        marginBottom: 3,
    },
    dateInfo: {
        marginBottom: 3,
    },
    paid: {
        color: 'purple',
        marginTop: 5,
    },
    infoContainer: {
        flexDirection: 'row',
        gap: 40,
        marginBottom: 40,
    },
    infoBox: {
        flex: 1,
        padding: 15,
    },
    companyBox: {
        backgroundColor: '#f0f0f0',
        color: '#181D27',
        marginRight: 25,
    },
    expenseBox: {
        borderWidth: 0.5,
        borderColor: '#000',
    },
    infoTitle: {
        marginBottom: 10,
    },
    infoText: {
        marginBottom: 3,
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        flexDirection: 'row',
        borderWidth: 0.5,
    },
    tableRow: {
        flexDirection: 'row',
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        borderBottomWidth: 0.5,
    },
    tableCell: {
        padding: 5,
        fontSize: 9,
        borderRightWidth: 0.5,
    },
    indexCell: { width: '5%' },
    descriptionCell: { width: '30%' },
    dateCell: { width: '15%' },
    typeCell: { width: '15%' },
    tvaCell: { width: '10%' },
    puCell: { width: '10%' },
    qteCell: { width: '5%' },
    totalCell: { width: '10%', borderRightWidth: 0 },
    totalsContainer: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    totalsTable: {
        width: '100%',
    },
    totalRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderWidth: 0.5,
        padding: 3,
    },
    totalLabel: {
        width: '60%',
    },
    totalValue: {
        width: '40%',
        textAlign: 'right',
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    footerText: {
        fontSize: 9,
        marginBottom: 5,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 30,
        right: 40,
        fontSize: 9,
    },
});

const itemsPerPage = 13;
export const PeriodPdfDocument = ({ periode, moderateur }) => {
    const totalPages = Math.ceil(periode.note_de_frais.length / itemsPerPage);
    const totalTva = periode.montant_ttc - periode.montant_ht;

    const renderPage = (pageNumber) => {
        const startIndex = (pageNumber - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const notesForPage = periode.note_de_frais.slice(startIndex, endIndex);

        return (
            <Page size="A4" style={styles.page} key={pageNumber}>
                {/* Header */}
                <View style={styles.header}>
                    <View style={styles.headerContent}>
                        <Image style={styles.logo} src="/maskable_icon_x384.png" />
                        <View style={styles.headerRight}>
                            <Text style={styles.title}>Note de frais</Text>
                            <Text style={styles.reference}>
                                Réf. : {periode.periode_note_de_frais_id}
                            </Text>
                            <Text style={styles.dateInfo}>
                                Date début : {moment(periode.date_debut).format('DD/MM/YYYY')}
                            </Text>
                            <Text style={styles.dateInfo}>
                                Date fin : {moment(periode.date_fin).format('DD/MM/YYYY')}
                            </Text>
                            <Text style={styles.paid}>Payé</Text>
                        </View>
                    </View>
                </View>

                {/* Informations */}
                <View style={styles.infoContainer}>
                    <View style={[styles.infoBox, styles.companyBox]}>
                        <Text style={styles.infoTitle}>Information société :</Text>
                        <Text style={styles.infoText}>NEODT</Text>
                        <Text style={styles.infoText}>7 rue des Augustins</Text>
                        <Text style={styles.infoText}>59800 - Lille</Text>
                        <Text style={styles.infoText}>Téléphone : 0374097007</Text>
                        <Text style={styles.infoText}>Email : rh@neodt.fr</Text>
                        <Text style={styles.infoText}>Web : www.neodt.fr</Text>
                    </View>
                    <View style={[styles.infoBox, styles.expenseBox]}>
                        <Text style={styles.infoTitle}>Informations note de frais :</Text>
                        <Text style={styles.infoText}>
                            Enregistré par :
                            {periode.utilisateurs[0].nom.toUpperCase() +
                                ' ' +
                                periode.utilisateurs[0].prenom}
                        </Text>
                        <Text style={styles.infoText}>
                            Date création : {moment(periode.created_at).format('DD/MM/YYYY')}
                        </Text>
                        <Text style={styles.infoText}>
                            Approuvé par :
                            {moderateur
                                ? moderateur.nom.toUpperCase() + ' ' + moderateur.prenom
                                : ' '}
                        </Text>
                        <Text style={styles.infoText}>
                            Date approbation :{moment(periode.updatedAt).format('DD/MM/YYYY')}
                        </Text>
                    </View>
                </View>

                {/* Tableau des notes de frais */}
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={[styles.tableCell, styles.indexCell]}>
                            <Text>N°</Text>
                        </View>
                        <View style={[styles.tableCell, styles.descriptionCell]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableCell, styles.dateCell]}>
                            <Text>Date</Text>
                        </View>
                        <View style={[styles.tableCell, styles.typeCell]}>
                            <Text>Type</Text>
                        </View>
                        <View style={[styles.tableCell, styles.tvaCell]}>
                            <Text>TVA</Text>
                        </View>
                        <View style={[styles.tableCell, styles.puCell]}>
                            <Text>P.U.</Text>
                        </View>
                        <View style={[styles.tableCell, styles.qteCell]}>
                            <Text>Qté</Text>
                        </View>
                        <View style={[styles.tableCell, styles.totalCell]}>
                            <Text>Total TTC</Text>
                        </View>
                    </View>

                    {notesForPage.map((note, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={[styles.tableCell, styles.indexCell]}>
                                <Text>{startIndex + index + 1}</Text>
                            </View>
                            <View style={[styles.tableCell, styles.descriptionCell]}>
                                <Text>{note.description}</Text>
                            </View>
                            <View style={[styles.tableCell, styles.dateCell]}>
                                <Text>{moment(note.date).format('DD/MM/YYYY')}</Text>
                            </View>
                            <View style={[styles.tableCell, styles.typeCell]}>
                                <Text>{note.type_note_de_frai.nom}</Text>
                            </View>
                            <View style={[styles.tableCell, styles.tvaCell]}>
                                <Text>{note.tva_valeur}%</Text>
                            </View>
                            <View style={[styles.tableCell, styles.puCell]}>
                                <Text>{note.prix_unitaire.toFixed(2)}</Text>
                            </View>
                            <View style={[styles.tableCell, styles.qteCell]}>
                                <Text>{note.quantite}</Text>
                            </View>
                            <View style={[styles.tableCell, styles.totalCell]}>
                                <Text>{note.montant_ttc.toFixed(2)}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                {/* Tableau des totaux */}
                {pageNumber === totalPages && (
                    <View style={styles.totalsContainer}>
                        <View style={styles.totalsTable}>
                            <View style={styles.totalRow}>
                                <Text style={styles.totalLabel}>Total HT</Text>
                                <Text style={styles.totalValue}>
                                    {periode.montant_ht.toFixed(2)}
                                </Text>
                            </View>
                            <View style={styles.totalRow}>
                                <Text style={styles.totalLabel}>Total TVA</Text>
                                <Text style={styles.totalValue}>{totalTva.toFixed(2)}</Text>
                            </View>
                            <View style={styles.totalRow}>
                                <Text style={styles.totalLabel}>Total TTC</Text>
                                <Text style={styles.totalValue}>
                                    {periode.montant_ttc.toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}

                {/* Footer */}
                <View style={styles.footer}>
                    <Text style={styles.footerText}>Montants exprimés en Euros</Text>
                    <Text style={styles.footerText}>SIRET: 81227483500022</Text>
                </View>

                <Text style={styles.pageNumber}>
                    {pageNumber}/{totalPages}
                </Text>
            </Page>
        );
    };

    return <Document>{[...Array(totalPages)].map((_, i) => renderPage(i + 1))}</Document>;
};
