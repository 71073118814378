import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import noteDeFraisService from '../../services/note_de_frais.service';
import moment from 'moment/moment';
import {
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Paper,
    Button,
    Box,
    CircularProgress,
    Typography,
    Pagination,
    Select,
    MenuItem,
} from '@mui/material';
import { H2Lighter, StripedTableRow, TableCellHead } from '../../theming/customTheme';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const DisplayPeriodeBrouillon = (props) => {
    const [periodeBrouillonState, setPeriodeBrouillonState] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const paginatedData = periodeBrouillonState.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const fetchPeriodes = () => {
        noteDeFraisService.getPeriodesBrouillonByUser(user.user_id).then((response) => {
            if (response.error) {
                toast.error('Une erreur est survenue : ', response.error);
            } else {
                setPeriodeBrouillonState(response);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        props.onLoad('Brouillon - Mes notes de frais');
        fetchPeriodes();
    }, []);

    return (
        <>
            <H2Lighter>Mes brouillons</H2Lighter>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TableContainer component={Paper} elevation={4} sx={{ mt: 1 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCellHead align="center">Id</TableCellHead>
                                    <TableCellHead align="center">Période concernée</TableCellHead>
                                    <TableCellHead align="center">Date de création</TableCellHead>
                                    <TableCellHead align="center">Actions</TableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.length === 0 ? (
                                    <StripedTableRow>
                                        <TableCell colSpan={4} align="center" sx={{ py: 3 }}>
                                            Aucune période trouvée.
                                        </TableCell>
                                    </StripedTableRow>
                                ) : (
                                    paginatedData.map((row) => (
                                        <StripedTableRow
                                            key={row.periode_note_de_frais_id}
                                            sx={{ '&:hover': { bgcolor: 'action.hover' } }}
                                        >
                                            <TableCell align="center">
                                                {row.periode_note_de_frais_id}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(row.date_debut).format('MMMM YYYY')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(row.createdAt).format('LL')}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link
                                                    to={'update'}
                                                    state={{
                                                        brouillon_periode_id:
                                                            row.periode_note_de_frais_id,
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{ borderRadius: 2 }}
                                                    >
                                                        <EditIcon sx={{ mr: 1 }} />
                                                        Modifier
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </StripedTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                        <Box flex={1} display="flex" justifyContent="center">
                            <Pagination
                                count={Math.ceil(periodeBrouillonState.length / rowsPerPage)}
                                page={page}
                                onChange={handlePageChange}
                                color="secondary"
                                sx={{ '& .MuiPaginationItem-root': { borderRadius: 2 } }}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" sx={{ mr: 1 }}>
                                Lignes par page
                            </Typography>
                            <Select
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 80, borderRadius: 2 }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedDisplayPeriodeBrouillon = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DisplayPeriodeBrouillon);
