import { useState, useEffect } from 'react'; // import { useState } from 'react';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import FormNoteDeFraisComponent from './form-note-de-frais.component';

const CreateNoteDeFrais = () => {
    const location = useLocation();
    const [periode, setPeriode] = useState(null);

    useEffect(() => {
        if (location.state && location.state.noteDeFrais) {
            const periodeAvecNotes = {
                ...location.state.noteDeFrais,
                note_de_frais: [],
            };
            setPeriode([periodeAvecNotes]);
        } else {
            setPeriode([]);
        }
    }, [location.state]);

    return (
        <>
            {periode ? (
                periode.length > 0 ? (
                    <FormNoteDeFraisComponent periodeBrouillon={periode} />
                ) : (
                    <p>Aucune période sélectionnée.</p>
                )
            ) : (
                <p>Chargement en cours...</p>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCreateNoteDeFrais = connect(null, mapDispatchToProps)(CreateNoteDeFrais);
