import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Typography,
    Grid,
    Pagination,
    Box,
    Button,
    Modal,
    MenuItem,
    Select,
} from '@mui/material';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { toast } from 'react-toastify';
import noteDeFraisService from '../../services/note_de_frais.service';
import { etatPeriodeNoteDeFrais } from '../../helpers/note_de_frais.helper';
import moment from 'moment/moment';
import { renderStatusChip } from './render-ship-status.component';
import { Close } from '@mui/icons-material';
import FileUploadComponent from '../admin/fichiers/file-upload.component';
import fichiersService from '../../services/fichiers.service';
import { H2Lighter, StripedTableRow, TableCellHead } from '../../theming/customTheme';

const displayNoteDeFraisUser = (props) => {
    const [periodeNoteDeFrais, setPeriodeNoteDeFrais] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPeriode, setSelectedPeriode] = useState(null);
    const [filesSate, setFilesState] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const paginatedData = periodeNoteDeFrais.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        fetchUserPeriods();
        props.onLoad('Mes périodes');
    }, []);

    const fetchUserPeriods = async () => {
        try {
            const response = await noteDeFraisService.getPeriodeNoteDeFraisByUser(user.user_id);
            setPeriodeNoteDeFrais(response);
        } catch (error) {
            toast.error('Erreur lors du chargement des périodes');
            console.error('Erreur lors de la récupération des périodes:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFichierSelectedPeriode = async (periode) => {
        try {
            const response = await fichiersService.getFichiersNDF(periode.periode_note_de_frais_id);
            setFilesState(response);
        } catch (error) {
            toast.error('Erreur lors du chargement de la note de frais');
            console.error('Erreur lors de la récupération des fichiers:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (periode) => {
        setSelectedPeriode(periode);
        fetchFichierSelectedPeriode(periode);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPeriode(null);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <>
            <H2Lighter>Mes notes de frais</H2Lighter>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TableContainer
                        component={Paper}
                        elevation={4}
                        sx={{ mt: 1, textAlign: 'center' }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCellHead align="center">Période</TableCellHead>
                                    <TableCellHead align="center">Statut</TableCellHead>
                                    <TableCellHead align="center">Actions</TableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.length === 0 ? (
                                    <StripedTableRow>
                                        <TableCell colSpan={3} align="center" sx={{ py: 3 }}>
                                            Aucune période disponible
                                        </TableCell>
                                    </StripedTableRow>
                                ) : (
                                    paginatedData.map((periode) => (
                                        <StripedTableRow
                                            key={periode.periode_note_de_frais_id}
                                            sx={{ '&:hover': { bgcolor: 'action.hover' } }}
                                        >
                                            <TableCell align="center">
                                                {moment(periode.date_debut).format('MMMM YYYY')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderStatusChip(etatPeriodeNoteDeFrais(periode))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleOpenModal(periode)}
                                                    sx={{ borderRadius: 2 }}
                                                >
                                                    Détails
                                                </Button>
                                            </TableCell>
                                        </StripedTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                        <Box flex={1} display="flex" justifyContent="center">
                            <Pagination
                                count={Math.ceil(periodeNoteDeFrais.length / rowsPerPage)}
                                page={page}
                                onChange={handlePageChange}
                                color="secondary"
                                sx={{ '& .MuiPaginationItem-root': { borderRadius: 2 } }}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" sx={{ mr: 1 }}>
                                Lignes par page
                            </Typography>
                            <Select
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 80, borderRadius: 2 }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </>
            )}

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        minWidth: { xs: '90%', sm: 600, md: 1200 },
                        borderRadius: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '2px solid #eee',
                            pb: 2,
                            mb: 3,
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold">
                            Détails de la Période
                        </Typography>
                        <Button onClick={handleCloseModal} sx={{ minWidth: 'auto', p: 1 }}>
                            <Close fontSize="large" />
                        </Button>
                    </Box>

                    <Grid container spacing={2} sx={{ mb: 3 }} justifyContent="space-between">
                        <Grid item xs={12} sm={6}>
                            <Typography align="left">
                                <strong>Période :</strong>{' '}
                                {moment(selectedPeriode ? selectedPeriode.date_fin : new Date())
                                    .format('MMMM YYYY')
                                    .toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Notes de frais :
                    </Typography>
                    <TableContainer
                        sx={{ maxHeight: 400, border: '1px solid #ddd', borderRadius: 2 }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {[
                                        'Date',
                                        'Type',
                                        'Description',
                                        'PU.HT',
                                        'Quantité',
                                        'Montant HT',
                                        'TVA',
                                        'Montant TTC',
                                    ].map((header) => (
                                        <TableCellHead
                                            align="center"
                                            key={header}
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {header}
                                        </TableCellHead>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedPeriode?.note_de_frais?.length > 0 ? (
                                    selectedPeriode.note_de_frais.map((note) => (
                                        <StripedTableRow key={note.note_de_frais_id} hover>
                                            <TableCell align="center">
                                                {moment(note.date).format('DD/MM')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {note.type_note_de_frai.nom}
                                            </TableCell>
                                            <TableCell align="center">{note.description}</TableCell>
                                            <TableCell align="center">
                                                {note.prix_unitaire} €
                                            </TableCell>
                                            <TableCell align="center">{note.quantite}</TableCell>
                                            <TableCell align="center">
                                                {note.montant_ht} €
                                            </TableCell>
                                            <TableCell align="center">
                                                {note.tva.valeur} %
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: 'primary.main',
                                                }}
                                            >
                                                {note.montant_ttc} €
                                            </TableCell>
                                        </StripedTableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
                                            Aucune note de frais disponible
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        display="flex"
                        justifyContent="right"
                        mt={3}
                        p={2}
                        sx={{ bgcolor: '#f9f9f9', borderRadius: 2 }}
                    >
                        <Typography variant="subtitle1">
                            <strong>Montant HT :</strong> {selectedPeriode?.montant_ht || 0} €
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 'bold', color: 'primary.main', ml: 3 }}
                        >
                            <strong>Montant TTC :</strong> {selectedPeriode?.montant_ttc || 0} €
                        </Typography>
                    </Box>

                    {selectedPeriode?.date_debut && (
                        <FileUploadComponent
                            username={user.nom}
                            periodeDate={moment(selectedPeriode.date_debut).format('MMMM-YYYY')}
                            isLoading={loading}
                            uploadedFiles={filesSate}
                            modeVisualisation={true}
                        />
                    )}
                </Box>
            </Modal>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedNotesDeFraisDisplay = connect(
    null,
    mapDispatchToProps,
)(displayNoteDeFraisUser);
