import { useState, React, useEffect } from 'react';
import SpaceSVG from '../../images/space-discovery.svg';
import './conge.style.scss';
// redux
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import congeService from '../../services/conge.service';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { Button, CircularProgress, Grid, Paper } from '@mui/material';
import CrudPageComponent from '../assets/page/crud-page.component';
import { Link } from 'react-router-dom';
import { displayStatusIcon } from '../../helpers/conge.helper';
import ErrorWarningComponent from '../assets/infos/error-warning.component';
import SimpleModalComponent from '../assets/modal/simple-modal.component';
import ListLogSoldesComponent from '../assets/list/list-log-soldes.component';

const CongeComponent = (props) => {
    const [contentState, setContentState] = useState('');
    const [loadingState, setLoadingState] = useState(true);
    const [errorState, setErrorState] = useState(false);
    const [isOpenState, setIsOpenState] = useState(false);
    const [isOpenStateNext, setIsOpenNextState] = useState(false);

    const fetchPersonalInfos = async () => {
        congeService.getPersonalInfos().then((personalInfos) => {
            setLoadingState(false);
            if (personalInfos.error) {
                setErrorState(
                    'Une erreur est survenue : ' +
                        personalInfos.error +
                        '. Veuillez contacter votre administrateur.',
                );
            } else {
                setContentState(personalInfos);
                setLoadingState(false);
            }
        });
    };

    const handleCloseModal = () => {
        setIsOpenState(false);
    };
    const handleCloseMNextodal = () => {
        setIsOpenNextState(false);
    };

    useEffect(() => {
        props.onLoad('Mes congés');
        fetchPersonalInfos();
    }, []);

    //gestion erreur
    if (loadingState) {
        return (
            <section className="body-main-container">
                <CircularProgress />
                <div className="background-decoration-svg">
                    <img src={SpaceSVG} height="550px" width="800px" alt="" />
                </div>
            </section>
        );
    }

    if (errorState) {
        return (
            <ErrorWarningComponent redirectTo={{ link: '/home', text: "Retour à l'accueil" }}>
                {errorState}
            </ErrorWarningComponent>
        );
    }

    return (
        <>
            <CrudPageComponent
                objectName="conges"
                title="Mes congés"
                titleAdd="Demander des congés"
                displayType={true}
                linkAdd="/my-times"
                headers={[
                    'Id',
                    'Date de début',
                    'Date de fin',
                    'Type de congé',
                    'Nombre de jour',
                    'État',
                ]}
                showId
                dataContent={
                    !!contentState
                        ? contentState.conges.map((conge) => ({
                              conges_id: conge.new_conges_id,
                              date_debut: new Date(conge.jour_conges[0].date).toLocaleDateString(),
                              date_fin: new Date(
                                  conge.jour_conges[conge.jour_conges.length - 1].date,
                              ).toLocaleDateString(),
                              type_conge: conge.type_conge.nom,
                              nb_jour: conge.solde,
                              etat: displayStatusIcon(conge, true, true),
                          }))
                        : [{}]
                }
                actionsAddedContent={() => {
                    return (
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            component={Link}
                            to={'/my-times'}
                        >
                            <VisibilityIcon sx={{ mr: 1 }} /> Voir
                        </Button>
                    );
                }}
            />
            <SimpleModalComponent isOpen={isOpenState} handleCloseModal={handleCloseModal}>
                <ListLogSoldesComponent
                    content={
                        contentState && {
                            utilisateur: contentState.userInfos,
                            soldes_utilisateurs_id:
                                contentState.soldeUtilisateur.soldes_utilisateurs_id,
                        }
                    }
                />
            </SimpleModalComponent>
            <SimpleModalComponent isOpen={isOpenStateNext} handleCloseModal={handleCloseMNextodal}>
                <ListLogSoldesComponent
                    content={
                        contentState && {
                            utilisateur: contentState.userInfos,
                            soldes_utilisateurs_id:
                                contentState.nextSoldeUtilisateur.soldes_utilisateurs_id,
                        }
                    }
                />
            </SimpleModalComponent>
            <Grid container direction="row-reverse" sx={{ mt: 2 }}>
                <Grid item xs={2}>
                    <Paper sx={{ p: 1 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => setIsOpenState(true)}
                        >
                            <b>Solde de congés : </b>{' '}
                            {contentState ? contentState.soldeUtilisateur.solde : 0}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mt: 1 }}
                            fullWidth
                            onClick={() => setIsOpenNextState(true)}
                        >
                            <b>Prochain solde : </b>{' '}
                            {contentState ? contentState.nextSoldeUtilisateur.solde : 0}
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCongeComponent = connect(null, mapDispatchToProps)(CongeComponent);
