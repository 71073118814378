import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FileUploadComponent from '../admin/fichiers/file-upload.component';
import noteDeFraisService from '../../services/note_de_frais.service';
import fichiersService from '../../services/fichiers.service';
import moment from 'moment/moment';
import { Controller, useForm } from 'react-hook-form';
import WaitingScreenComponent from '../assets/waiting-screen.component';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Save, Edit, Delete } from '@mui/icons-material';

const FormNoteDeFraisComponent = (props) => {
    const propsPeriode = props.periodeBrouillon;
    const user = JSON.parse(localStorage.getItem('user'));
    const [periode, setPeriode] = useState(null);
    const notes = propsPeriode && propsPeriode.length > 0 ? propsPeriode[0].note_de_frais : [];
    const isUpdate = notes ? true : false;
    const [tvaOptions, setTvaOptions] = useState([]);
    const [typeNoteDeFraisOptions, setTypeNoteDeFraisOptions] = useState([]);
    const [noteState, setNoteState] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const isAnyNoteEditing = noteState.some((note) => note.isEditing === true);
    const [noteDeFraisCategoryId, setNoteDeFraisCategoryId] = useState(null);
    const navigate = useNavigate();

    const {
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (propsPeriode && propsPeriode.length > 0) {
            const periodeId = propsPeriode[0].periode_note_de_frais_id;
            const storageKey = `noteState_${periodeId}`;
            const savedNotes = JSON.parse(localStorage.getItem(storageKey));

            if (savedNotes) {
                if (propsPeriode[0].note_de_frais && propsPeriode[0].note_de_frais.length > 0) {
                    const existingIds = savedNotes.map((note) => note.note_de_frais_id);
                    const newNotes = propsPeriode[0].note_de_frais
                        .filter((note) => !existingIds.includes(note.note_de_frais_id))
                        .map((note) => ({
                            ...note,
                            isEditing: false,
                        }));

                    if (newNotes.length > 0) {
                        setNoteState([...savedNotes, ...newNotes]);
                    } else {
                        setNoteState(savedNotes);
                    }
                } else {
                    setNoteState(savedNotes);
                }
            } else if (propsPeriode[0].note_de_frais) {
                setNoteState(
                    propsPeriode[0].note_de_frais.map((note) => ({
                        ...note,
                        isEditing: false,
                    })),
                );
            }

            setPeriode(propsPeriode);
            setLoading(false);
        }
    }, [propsPeriode]);

    const purgeLocalStorage = () => {
        if (periode && periode[0]) {
            const storageKey = `noteState_${periode[0].periode_note_de_frais_id}`;
            localStorage.removeItem(storageKey);
        }
    };

    useEffect(() => {
        if (periode && periode[0] && periode[0].periode_note_de_frais_id) {
            fetchExistingFiles();
        }
    }, [periode]);

    useEffect(() => {
        fetchTvaValues();
        fetchTypesValues();
        handleFetchCategory();
    }, []);

    useEffect(() => {
        if (periode && periode[0]) {
            const storageKey = `noteState_${periode[0].periode_note_de_frais_id}`;
            localStorage.setItem(storageKey, JSON.stringify(noteState));
        }
    }, [noteState, periode]);

    const handleFetchCategory = async () => {
        try {
            const categorie = await fichiersService.getCategorieNoteDeFrais();

            if (categorie.error) {
                toast.error(categorie.error);
            } else {
                if (categorie) {
                    setNoteDeFraisCategoryId(categorie.category_fichiers_id);
                } else {
                    toast.error('Catégorie "note-de-frais" non trouvée');
                }
            }
        } catch (error) {
            toast.error(`Erreur lors du chargement des catégories : ${error.message}`);
        }
    };

    const fetchExistingFiles = async () => {
        try {
            const fichiers = await fichiersService.getFichiersNDF(
                periode[0].periode_note_de_frais_id,
            );
            setFiles(fichiers);
        } catch (error) {
            toast.error(`Erreur lors du chargement des fichiers: ${error.message}`);
        }
    };

    const fetchTvaValues = async () => {
        try {
            const response = await noteDeFraisService.getAllTVA();
            const sortedTvaOptions = response.sort((a, b) => a.valeur - b.valeur);
            setTvaOptions(sortedTvaOptions);
        } catch (error) {
            console.error('Erreur lors de la récupération des valeurs de TVA :', error);
        }
    };

    const fetchTypesValues = async () => {
        try {
            const response = await noteDeFraisService.getAllNoteDeFraisType();
            setTypeNoteDeFraisOptions(response);
        } catch (error) {
            console.error('Erreur lors de la récupération des valeurs de TVA :', error);
        }
    };

    const addNote = () => {
        setNoteState([
            ...noteState,
            {
                note_de_frais_id:
                    noteState.length > 0 ? noteState[noteState.length - 1].note_de_frais_id + 1 : 1,
                date: '',
                type_note_de_frai: typeNoteDeFraisOptions[0],
                description: '',
                tva: tvaOptions[0],
                prix_unitaire: 0,
                quantite: 0,
                montant_ht: 0.0,
                montant_ttc: 0.0,
                isEditing: true,
                isFromProps: true,
                isNotCreated: true,
            },
        ]);
    };

    const toggleEditMode = async (index) => {
        const updatedNotes = [...noteState];
        if (updatedNotes[index].isEditing && updatedNotes[index].isNotCreated) {
            const newNoteId = await createNote(index);
            if (newNoteId) {
                updatedNotes[index].note_de_frais_id = newNoteId;
                updatedNotes[index].isNotCreated = false;
            }
        } else if (updatedNotes[index].isEditing) {
            updateNoteInDb(index);
        }

        updatedNotes[index].isEditing = !updatedNotes[index].isEditing;
        setNoteState(updatedNotes);
    };

    const createNote = async (index) => {
        const note = noteState[index];

        try {
            const response = await noteDeFraisService.createNoteDeFrais({
                description: note.description,
                prix_unitaire: note.prix_unitaire,
                montant_ht: note.montant_ht,
                montant_ttc: note.montant_ttc,
                quantite: note.quantite,
                date: note.date,
                fk_type_note_de_frais: note.type_note_de_frai.type_note_de_frais_id,
                fk_periode_note_de_frais: periode[0].periode_note_de_frais_id,
                fk_tva: note.tva.tva_id,
            });

            if (response && !response.error && response.noteDeFraisId) {
                toast.success('Note de frais créée');
                return response.noteDeFraisId;
            } else {
                toast.error(`Erreur lors de la création de la note de frais : ${response.error}`);
            }
        } catch (error) {
            toast.error(`Erreur lors de la création de la note de frais : ${error.message}`);
        }
    };

    const validateNote = (note) => {
        const dateDebut = new Date(periode[0].date_debut);
        const dateFin = new Date(periode[0].date_fin);
        const noteDate = new Date(note.date);

        const dateDebutDay = new Date(
            dateDebut.getFullYear(),
            dateDebut.getMonth(),
            dateDebut.getDate(),
        );
        const dateFinDay = new Date(dateFin.getFullYear(), dateFin.getMonth(), dateFin.getDate());
        const noteDateDay = new Date(
            noteDate.getFullYear(),
            noteDate.getMonth(),
            noteDate.getDate(),
        );

        if (noteDateDay >= dateDebutDay && noteDateDay <= dateFinDay) {
            return (
                note.type_note_de_frai && note.tva && note.prix_unitaire > 0 && note.quantite > 0
            );
        }
        toast.error(
            `La date de la note de frais avec l'id numéro : ${note.note_de_frais_id.toString()} sort de la période`,
        );
        return false;
    };

    const updateNoteInDb = async (index) => {
        const note = noteState[index];

        try {
            const response = await noteDeFraisService.updateNoteDeFrais(note.note_de_frais_id, {
                description: note.description,
                prix_unitaire: note.prix_unitaire,
                montant_ht: note.montant_ht,
                montant_ttc: note.montant_ttc,
                quantite: note.quantite,
                date: note.date,
                fk_type_note_de_frais: note.type_note_de_frai.type_note_de_frais_id,
                fk_periode_note_de_frais: periode[0].periode_note_de_frais_id,
                fk_tva: note.tva.tva_id,
            });

            if (response && !response.error) {
                updateNote(index, 'description', note.description);
                updateNote(index, 'prix_unitaire', note.prix_unitaire);
                updateNote(index, 'montant_ht', note.montant_ht);
                updateNote(index, 'montant_ttc', note.montant_ttc);
                updateNote(index, 'quantite', note.quantite);
                updateNote(index, 'date', note.date);
                updateNote(index, 'type_note_de_frai', {
                    type_note_de_frais_id: note.type_note_de_frai.type_note_de_frais_id,
                });
                updateNote(index, 'tva', {
                    tva_id: note.tva.tva_id,
                });
                updateNote(index, 'tva_valeur', note.tva.valeur);
                toast.success('Note de frais modifiée');
            } else {
                toast.error(`Erreur lors de la création de la note de frais : ${response.error}`);
            }
        } catch (error) {
            toast.error(`Erreur lors de la création de la note de frais : ${error.message}`);
        }
    };

    const updateNote = (index, field, value) => {
        const updatedNotes = [...noteState];
        updatedNotes[index] = {
            ...updatedNotes[index],
            [field]: value,
        };

        if (field === 'tva' || field === 'prix_unitaire' || field === 'quantite') {
            const amounts = calculateAmounts(updatedNotes[index]);
            updatedNotes[index].montant_ht = amounts.montantHt;
            updatedNotes[index].montant_ttc = amounts.montantTtc;
        }

        setNoteState(updatedNotes);
    };

    const handleFileUpload = async (fileData) => {
        if (fileData.length + files.length > noteState.length) {
            toast.error(
                'Vous ne pouvez pas ajouter plus de justificatifs que de de ligne de note de frais.',
            );
            return;
        }

        try {
            await Promise.all(
                fileData.map(async (file) => {
                    if (!['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)) {
                        toast.error(`Le format du fichier ${file.name} n'est pas autorisé.`);
                        return;
                    }

                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('periodeId', periode[0].periode_note_de_frais_id);
                    formData.append('userName', user.nom);
                    formData.append(
                        'periodeDate',
                        moment(periode[0].date_debut).format('MMMM-YYYY'),
                    );

                    const info = {
                        userName: user.nom,
                        periodeDate: moment(periode[0].date_debut).format('MMMM-YYYY'),
                    };

                    const uploadResponse = await fichiersService.uploadFileNDF(formData, info);

                    if (uploadResponse.error) {
                        throw new Error(uploadResponse.error);
                    }

                    const createResponse = await fichiersService.insertFichierNDF({
                        name: file.name,
                        description: ' ',
                        fk_category: noteDeFraisCategoryId,
                        check_entity: periode[0].fk_entity,
                        fichier: uploadResponse.fichier.filename,
                        is_pinned: false,
                        fk_periode_note_de_frais: periode[0].periode_note_de_frais_id,
                    });

                    if (createResponse.error) {
                        throw new Error(createResponse.error);
                    }

                    toast.success(`Le fichier ${file.name} a été uploadé avec succès.`);
                }),
            );

            await fetchExistingFiles();
        } catch (error) {
            toast.error(`Erreur lors de l'upload : ${error.message}`);
        }
    };

    const handleFileDelete = async (fileId) => {
        try {
            const fileToDelete = files.find((file) => file.fichiers_id === fileId);

            const response = await fichiersService.deleteFichierNDFFromBase(fileId);

            if (response.error) {
                throw new Error(response.error);
            } else {
                await fichiersService.deleteFichierNDF(
                    user.nom,
                    fileToDelete.fichier,
                    moment(periode[0].date_debut).format('MMMM-YYYY'),
                );
                toast.success('Fichier supprimé avec succès');
                await fetchExistingFiles();
            }
        } catch (error) {
            toast.error(`Erreur lors de la suppression: ${error.message}`);
        }
    };

    const calculateAmounts = (note) => {
        const puTtc = parseFloat(note.prix_unitaire);
        const quantite = parseFloat(note.quantite);
        const tvaRate =
            note.tva && !isNaN(parseFloat(note.tva.valeur))
                ? parseFloat(note.tva.valeur) / 100
                : parseFloat(tvaOptions[0].valeur) / 100;

        if (quantite > 0) {
            const montantTtc = puTtc * quantite;
            const montantHt = montantTtc / (1 + tvaRate);

            return {
                montantHt: montantHt.toFixed(2),
                montantTtc: montantTtc.toFixed(2),
            };
        }
        return {
            montant_ht: 0.0,
            montant_ttc: 0.0,
        };
    };

    const removeNote = (index) => {
        const note = noteState[index];

        if (note.note_de_frais_id && note.isNotCreated == true) {
            setNoteState(noteState.filter((_, i) => i !== index));
        } else {
            try {
                noteDeFraisService
                    .deleteNoteDefrais(note.note_de_frais_id, user.user_id)
                    .then((response) => {
                        toast.success(response.message);
                    })
                    .catch((error) => toast.error(error));
                const updatedNotes = noteState.filter((_, i) => i !== index);
                setNoteState(updatedNotes);
            } catch (error) {
                console.error('Erreur lors de la suppression de la note de frais :', error);
            }
        }
    };

    const datePeriodValid = () => {
        const dateDebut = new Date(periode[0].date_debut);
        const dateFin = new Date(periode[0].date_fin);

        noteState.forEach((note) => {
            const noteDate = new Date(note.date);

            if (noteDate < dateDebut || noteDate > dateFin) {
                toast.error(
                    `La date de la note de frais avec l'id numéro : ${note.note_de_frais_id.toString()} sort de la période`,
                );
                return false;
            }
        });
    };

    const handleSubmit = async () => {
        datePeriodValid();
        try {
            if (noteState.length > 0) {
                noteDeFraisService
                    .validePeriodeNoteDeFrais(periode[0].periode_note_de_frais_id)
                    .then((response) => {
                        if (response && !response.error) {
                            toast.success('Vos notes de frais ont été transmises');
                            purgeLocalStorage();
                            navigate('/note-de-frais/');
                        } else {
                            toast.error(
                                `Erreur lors de la transmission de vos notes de frais : ${response.error}`,
                            );
                        }
                    });
            } else {
                toast.info('La période ne contient pas de note de frais');
            }
        } catch (error) {
            console.error('Erreur lors de la validation de la période :', error);
        }
    };

    const getTvaValue = (note) => {
        if (note.fk_tva === null) {
            return note.tva_valeur;
        }
        if (note.isFromProps) {
            return note.tva.valeur;
        } else if (note.tva.valeur !== note.tva_valeur) {
            return note.tva_valeur;
        } else {
            return note.tva.valeur;
        }
    };

    return (
        <>
            {!loading ? (
                <Box sx={{ mt: 4 }}>
                    <Typography ml={2} fontSize={18} color="primary.main" mb={2}>
                        Période : {moment(periode[0].date_debut).format('MMMM YYYY')}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" color="primary.main">
                                        #
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Date
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Type
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Description
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        TVA (%)
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        P.U TTC (€)
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Qté
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Montant HT (€)
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Montant TTC (€)
                                    </TableCell>
                                    <TableCell align="center" color="primary.main">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {noteState.length > 0 ? (
                                    noteState.map((note, index) => (
                                        <TableRow
                                            key={note.note_de_frais_id}
                                            sx={{
                                                height: '48px',
                                                backgroundColor:
                                                    index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                                            }}
                                        >
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.note_de_frais_id}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {isUpdate ? (
                                                    <Controller
                                                        key={`inputDateCommande-${index}`}
                                                        name={`inputDateCommande-${index}`}
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message:
                                                                    'La date de commande est requise',
                                                            },
                                                        }}
                                                        defaultValue={note.date || ''}
                                                        render={({
                                                            field: { value, onChange },
                                                        }) => (
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment}
                                                                adapterLocale="fr"
                                                            >
                                                                <DatePicker
                                                                    value={value}
                                                                    disabled={!note.isEditing}
                                                                    minDate={periode[0].date_debut}
                                                                    maxDate={periode[0].date_fin}
                                                                    onChange={(newValue) => {
                                                                        onChange(newValue.toDate());
                                                                        updateNote(
                                                                            index,
                                                                            'date',
                                                                            newValue.toDate(),
                                                                        );
                                                                    }}
                                                                    defaultCalendarMonth={moment(
                                                                        periode[0].date_debut,
                                                                    )}
                                                                    openTo="day"
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            sx={{
                                                                                mt: 1,
                                                                                '& .MuiInputBase-root':
                                                                                    {
                                                                                        height: '36px',
                                                                                    },
                                                                            }}
                                                                            error={
                                                                                !!errors[
                                                                                    `inputDateCommande-${index}`
                                                                                ]
                                                                            }
                                                                            helperText={
                                                                                errors[
                                                                                    `inputDateCommande-${index}`
                                                                                ]
                                                                            }
                                                                            label="Date de la note de frais"
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        )}
                                                    />
                                                ) : (
                                                    note.date
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.isEditing ? (
                                                    <FormControl
                                                        fullWidth
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '36px',
                                                            },
                                                        }}
                                                    >
                                                        <InputLabel>Type</InputLabel>
                                                        <Select
                                                            value={
                                                                note.type_note_de_frai
                                                                    .type_note_de_frais_id
                                                            }
                                                            label="Type"
                                                            onChange={(e) => {
                                                                const typeSelected =
                                                                    typeNoteDeFraisOptions.find(
                                                                        (type) =>
                                                                            type.type_note_de_frais_id ===
                                                                            e.target.value,
                                                                    );
                                                                updateNote(
                                                                    index,
                                                                    'type_note_de_frai',
                                                                    typeSelected,
                                                                );
                                                            }}
                                                        >
                                                            {Array.isArray(
                                                                typeNoteDeFraisOptions,
                                                            ) &&
                                                            typeNoteDeFraisOptions.length > 0 ? (
                                                                typeNoteDeFraisOptions.map(
                                                                    (type) => (
                                                                        <MenuItem
                                                                            key={
                                                                                type.type_note_de_frais_id
                                                                            }
                                                                            value={
                                                                                type.type_note_de_frais_id
                                                                            }
                                                                        >
                                                                            {type.nom}
                                                                        </MenuItem>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <MenuItem disabled>
                                                                    Pas de Type disponible
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    `${note.type_note_de_frai.nom}`
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.isEditing ? (
                                                    <TextField
                                                        value={note.description}
                                                        onChange={(e) =>
                                                            updateNote(
                                                                index,
                                                                'description',
                                                                e.target.value,
                                                            )
                                                        }
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '36px',
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    note.description
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.isEditing ? (
                                                    <FormControl
                                                        fullWidth
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '36px',
                                                            },
                                                        }}
                                                    >
                                                        <InputLabel>TVA</InputLabel>
                                                        <Select
                                                            value={
                                                                note.tva?.tva_id
                                                                    ? note.tva.tva_id
                                                                    : tvaOptions[0].tva_id
                                                            }
                                                            label="TVA"
                                                            onChange={(e) => {
                                                                const selectedTva = tvaOptions.find(
                                                                    (tva) =>
                                                                        tva.tva_id ===
                                                                        e.target.value,
                                                                );
                                                                updateNote(
                                                                    index,
                                                                    'tva',
                                                                    selectedTva,
                                                                );
                                                            }}
                                                        >
                                                            {Array.isArray(tvaOptions) &&
                                                            tvaOptions.length > 0 ? (
                                                                tvaOptions.map((tva) => (
                                                                    <MenuItem
                                                                        key={tva.tva_id}
                                                                        value={tva.tva_id}
                                                                    >
                                                                        {tva.valeur}%
                                                                    </MenuItem>
                                                                ))
                                                            ) : (
                                                                <MenuItem disabled>
                                                                    Pas de TVA disponible
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    `${getTvaValue(note)}%`
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.isEditing ? (
                                                    <TextField
                                                        value={note.prix_unitaire}
                                                        onChange={(e) => {
                                                            let newValue = e.target.value.replace(
                                                                ',',
                                                                '.',
                                                            );
                                                            if (/^\d*\.?\d{0,3}$/.test(newValue)) {
                                                                updateNote(
                                                                    index,
                                                                    'prix_unitaire',
                                                                    newValue,
                                                                );
                                                            }
                                                        }}
                                                        inputProps={{
                                                            maxLength: 8,
                                                            min: 0,
                                                        }}
                                                        helperText={
                                                            !/^\d*\.?\d{0,2}$/.test(note.quantite)
                                                                ? 'Entrez un nombre avec maximum 2 décimales'
                                                                : ''
                                                        }
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '36px',
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    note.prix_unitaire
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.isEditing ? (
                                                    <TextField
                                                        value={note.quantite}
                                                        onChange={(e) => {
                                                            let newValue = e.target.value.replace(
                                                                ',',
                                                                '.',
                                                            );
                                                            if (/^\d*\.?\d{0,2}$/.test(newValue)) {
                                                                updateNote(
                                                                    index,
                                                                    'quantite',
                                                                    newValue,
                                                                );
                                                            }
                                                        }}
                                                        inputProps={{
                                                            maxLength: 8,
                                                            min: 0,
                                                        }}
                                                        helperText={
                                                            !/^\d*\.?\d{0,2}$/.test(note.quantite)
                                                                ? 'Entrez un nombre avec maximum 2 décimales'
                                                                : ''
                                                        }
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '36px',
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    note.quantite
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.montant_ht}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                {note.montant_ttc}
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '8px' }}>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    justifyContent="center"
                                                >
                                                    <Button
                                                        onClick={() => toggleEditMode(index)}
                                                        disabled={
                                                            note.isEditing && !validateNote(note)
                                                        }
                                                    >
                                                        {note.isEditing ? <Save /> : <Edit />}
                                                    </Button>
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => removeNote(index)}
                                                    >
                                                        <Delete />
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={10} align="center">
                                            Aucune note de frais pour cette période - Veuillez
                                            ajouter une nouvelle note de frais pour le mois de{' '}
                                            {moment(periode[0].date_debut).format('MMMM YYYY')}
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell colSpan={10} align="center">
                                        <Button
                                            component="label"
                                            color="success"
                                            variant="outlined"
                                            startIcon={<AddCircleIcon />}
                                            onClick={addNote}
                                        >
                                            Ajouter une ligne
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                        <Grid sx={{ ml: 3 }} item>
                            <FileUploadComponent
                                onFileUpload={handleFileUpload}
                                onFileDelete={handleFileDelete}
                                username={user.nom}
                                periodeDate={moment(periode[0].date_debut).format('MMMM-YYYY')}
                                isLoading={loading}
                                uploadedFiles={files}
                                formatsAuthorises={['image/png', 'image/jpeg', 'application/pdf']}
                                nbFichierMax={noteState.length}
                                buttonLabel="Ajouter un fichier"
                                // modeVisualisation={true}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{ mt: 2, mr: 5 }}
                                disabled={isAnyNoteEditing || noteState.length === 0}
                            >
                                Soumettre les notes de frais
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <WaitingScreenComponent />
            )}
        </>
    );
};
export default FormNoteDeFraisComponent;
