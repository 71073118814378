import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import SpaceSVG from '../../../../images/space-discovery.svg';
import '../../../conge/conge.style.scss';
import ErrorWarningComponent from '../../../assets/infos/error-warning.component';
import CrudPageComponent from '../../../assets/page/crud-page.component';
import congeService from '../../../../services/conge.service';
import { displayStatusIcon, dureeCongesFunction } from '../../../../helpers/conge.helper';
import { setPageTitle } from '../../../../redux/actions/scopeAction';
import { CircularProgress, Button, TextField, Autocomplete } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TablePagination from '@mui/material/TablePagination';

const ReportingCongeComponent = (props) => {
    const firstDayOfLastMonth = moment().subtract(1, 'months').startOf('month');
    const lastDayOfCurrentMonth = moment().endOf('month');

    const [loadingState, setLoadingState] = useState(true);
    const [errorState, setErrorState] = useState(null);
    const [contentState, setContentState] = useState([]);
    const [displayDatePickerState, setDisplayDatePickerState] = useState(true);
    const [isDateDebutOrderedState, setIsDateDebutOrderedState] = useState(false);
    const [isDateFinOrderedState, setIsDateFinOrderedState] = useState(false);
    const [filterDateDebut, setFilterDateDebut] = useState(firstDayOfLastMonth);
    const [filterDateFin, setFilterDateFin] = useState(lastDayOfCurrentMonth);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [showAll, setShowAll] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedTypeConge, setSelectedTypeConge] = useState(null);

    useEffect(() => {
        props.onLoad('Manager - Reporting congés');
        congeService.getAllCongesByEntity().then((response) => {
            if (!response.error) {
                setContentState(response);
                setLoadingState(false);
            } else {
                toast.error(response.error);
                setErrorState({ error: response.error });
            }
        });
    }, []);

    useEffect(() => {}, [filterDateDebut, filterDateFin]);

    const uniqueData = (array, keyExtractor) => {
        return array.reduce((acc, current) => {
            const key = keyExtractor(current);
            if (!acc.find((item) => keyExtractor(item) === key)) {
                acc.push(current);
            }
            return acc;
        }, []);
    };

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    const getUniqueUsers = () => {
        const users = contentState.map(
            (conge) =>
                `${capitalize(conge.utilisateur.nom)} ${capitalize(conge.utilisateur.prenom)}`,
        );
        return [...new Set(users)];
    };

    const getUniqueTypeConge = () => {
        return uniqueData(contentState, (conge) => conge.type_conge.type_conge_id).map((conge) => ({
            label: conge.type_conge.nom,
            id: conge.type_conge.type_conge_id,
        }));
    };

    const getUniqueEtat = () => {
        const allEtats = contentState
            .map((conge) => {
                if (conge.est_evalue === true && conge.est_valide === true) {
                    return { label: 'Validé', key: 'valide' };
                } else if (conge.est_evalue === true && conge.est_valide === false) {
                    return { label: 'Refusé', key: 'refuse' };
                } else if (conge.est_evalue === false && conge.est_valide === null) {
                    return { label: 'En attente', key: 'en_attente' };
                }
                return null;
            })
            .filter(Boolean);

        return uniqueData(allEtats, (etat) => etat.key);
    };

    const handleDisplayDatePicker = () => {
        setDisplayDatePickerState(!displayDatePickerState);
    };

    const handleToggleDatePicker = () => {
        handleClearDatePicker();
        setDisplayDatePickerState(!displayDatePickerState);
    };

    const handleClearDatePicker = () => {
        setFilterDateDebut(null);
        setFilterDateFin(null);
    };
    const handleResetFilters = () => {
        setFilterDateDebut(null);
        setFilterDateFin(null);
        setSelectedEtat(null);
        setSelectedUser(null);
        setSelectedTypeConge(null);
    };

    const handleUserChange = (event, newValue) => {
        setSelectedUser(newValue ? newValue : null);
    };

    const handleTypeCongeChange = (event, newValue) => {
        setSelectedTypeConge(newValue ? newValue : null);
    };

    const handleEtatChange = (event, newValue) => {
        setSelectedEtat(newValue ? newValue : null);
    };

    const reorderDateDebut = () => {
        if (contentState && contentState.length > 1) {
            const stateArray = [...contentState];
            let ordered = stateArray;
            if (isDateDebutOrderedState) {
                ordered = stateArray.sort(
                    (a, b) =>
                        new Date(a['jour_conges'][0]['date']) -
                        new Date(b['jour_conges'][0]['date']),
                );
            } else {
                ordered = stateArray.sort(
                    (a, b) =>
                        new Date(b['jour_conges'][0]['date']) -
                        new Date(a['jour_conges'][0]['date']),
                );
            }
            setContentState(ordered);
            setIsDateDebutOrderedState(!isDateDebutOrderedState);
        }
    };

    const reorderDateDeFin = () => {
        if (contentState && contentState.length > 1) {
            const stateArray = [...contentState];
            let ordered = stateArray;
            if (isDateFinOrderedState) {
                ordered = stateArray.sort(
                    (a, b) =>
                        new Date(a['jour_conges'][0]['date']) -
                        new Date(b['jour_conges'][0]['date']),
                );
            } else {
                ordered = stateArray.sort(
                    (a, b) =>
                        new Date(b['jour_conges'][0]['date']) -
                        new Date(a['jour_conges'][0]['date']),
                );
            }
            setContentState(ordered);
            setIsDateFinOrderedState(!isDateFinOrderedState);
        }
    };

    const filterByDate = (conge) => {
        if (!conge) return false;

        const dateDebut = moment(
            new Date(conge.jour_conges[0].date).toLocaleDateString(),
            'DD/MM/YYYY',
        );
        const dateFin = moment(
            new Date(conge.jour_conges[conge.jour_conges.length - 1].date).toLocaleDateString(),
            'DD/MM/YYYY',
        );

        if (!dateDebut.isValid() || !dateFin.isValid()) {
            return false;
        }

        const filterDebut = filterDateDebut ? moment(filterDateDebut).startOf('day') : null;
        const filterFin = filterDateFin ? moment(filterDateFin).endOf('day') : null;

        return (
            !filterDebut ||
            dateDebut.isBetween(filterDateDebut, filterFin) ||
            !filterFin ||
            dateFin.isBetween(filterDateDebut, filterFin)
        );
    };

    const filterCongesByEtat = (conge) => {
        if (!conge) return false;

        const estEvalue = conge.est_evalue !== undefined ? conge.est_evalue : false;
        const estValide = conge.est_valide !== undefined ? conge.est_valide : null;

        const etatMatch = (() => {
            if (!selectedEtat) return true;

            switch (selectedEtat.key) {
                case 'valide':
                    return estEvalue === true && estValide === true;
                case 'refuse':
                    return estEvalue === true && estValide === false;
                case 'en_attente':
                    return estEvalue === false && estValide === null;
                default:
                    return true;
            }
        })();

        return etatMatch;
    };

    const filteredContent = contentState
        .filter((conge) => {
            const dateMatch = filterByDate(conge);
            const utilisateurMatch = selectedUser
                ? `${capitalize(conge.utilisateur.nom)} ${capitalize(conge.utilisateur.prenom)}` ===
                  selectedUser
                : true;

            const etatMatch = filterCongesByEtat(conge);
            const typeCongeMatch = selectedTypeConge
                ? conge.type_conge.nom === selectedTypeConge.label
                : true;

            return utilisateurMatch && typeCongeMatch && etatMatch && dateMatch;
        })
        .map((conge) => ({
            utilisateur: `${capitalize(conge.utilisateur.nom)} ${capitalize(
                conge.utilisateur.prenom,
            )}`,
            date_creation: new Date(conge.createdAt).toLocaleDateString(),
            date_debut: new Date(conge.jour_conges[0].date).toLocaleDateString(),
            date_fin: new Date(
                conge.jour_conges[conge.jour_conges.length - 1].date,
            ).toLocaleDateString(),
            type: conge.type_conge.nom,
            duree: dureeCongesFunction(conge),
            etat: displayStatusIcon(conge, true, true),
        }));

    const paginatedContentState = showAll
        ? filteredContent
        : filteredContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value === -1) {
            setShowAll(true);
            setRowsPerPage(contentState.length);
            setPage(0);
        } else {
            setShowAll(false);
            setRowsPerPage(value);
            setPage(0);
        }
    };

    if (loadingState) {
        return (
            <section className="body-main-container">
                <CircularProgress />
                <div className="background-decoration-svg">
                    <img src={SpaceSVG} height="550px" width="800px" alt="" />
                </div>
            </section>
        );
    }

    if (errorState) {
        return (
            <ErrorWarningComponent redirectTo={{ link: '/home', text: "Retour à l'accueil" }}>
                {errorState.error}
            </ErrorWarningComponent>
        );
    }

    return (
        <>
            <CrudPageComponent
                showId
                title="Reporting Congés"
                objectName="conges"
                loading={loadingState}
                headers={[
                    {
                        filter: {
                            component: (
                                <Autocomplete
                                    options={getUniqueUsers()}
                                    getOptionLabel={(option) => option}
                                    value={selectedUser}
                                    onChange={handleUserChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Utilisateur"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{ width: 200 }}
                                />
                            ),
                        },
                    },
                    'Date de création',
                    {
                        title: 'Date de début',
                        filter: {
                            filterState: isDateDebutOrderedState,
                            filterFunction: reorderDateDebut,
                        },
                    },
                    {
                        title: 'Date de fin',
                        filter: {
                            filterState: isDateFinOrderedState,
                            filterFunction: reorderDateDeFin,
                        },
                    },
                    {
                        title: 'Type de congé',
                        filter: {
                            component: (
                                <Autocomplete
                                    options={getUniqueTypeConge()}
                                    getOptionLabel={(option) =>
                                        option && option.label ? option.label : ''
                                    }
                                    value={selectedTypeConge}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.label}
                                        </li>
                                    )}
                                    onChange={handleTypeCongeChange}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Type de congés"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{ width: 200 }}
                                />
                            ),
                        },
                    },
                    'Nombre de jour',
                    {
                        title: 'État',
                        filter: {
                            component: (
                                <Autocomplete
                                    options={getUniqueEtat()}
                                    getOptionLabel={(option) =>
                                        option && option.label ? option.label : ''
                                    }
                                    value={selectedEtat}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.key}>
                                            {option.label}
                                        </li>
                                    )}
                                    onChange={handleEtatChange}
                                    isOptionEqualToValue={(option, value) =>
                                        option.key === value.key
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="État" variant="outlined" />
                                    )}
                                    sx={{ width: 200 }}
                                />
                            ),
                        },
                    },
                ]}
                dataContent={paginatedContentState}
                buttonsAddedContent={() => {
                    return (
                        <>
                            <Button
                                variant="contained"
                                onClick={handleDisplayDatePicker}
                                sx={{ mr: 1, mt: 1 }}
                            >
                                <SearchIcon sx={{ mr: 1 }} />
                                Filtrer par date
                            </Button>
                            {displayDatePickerState && (
                                <>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale={'fr'}
                                    >
                                        <DatePicker
                                            label="Date de début"
                                            value={filterDateDebut}
                                            onChange={(newDate) => {
                                                setFilterDateDebut(newDate);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            sx={{ ml: 2 }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale={'fr'}
                                    >
                                        <DatePicker
                                            label="Date de fin"
                                            value={filterDateFin}
                                            onChange={(newDate) => {
                                                setFilterDateFin(newDate);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            sx={{ ml: 2 }}
                                        />
                                    </LocalizationProvider>
                                    <CancelIcon
                                        size="small"
                                        sx={{ ml: 1 }}
                                        onClick={handleToggleDatePicker}
                                    />
                                </>
                            )}
                            <Button
                                variant="contained"
                                onClick={handleResetFilters}
                                sx={{ mr: 1, mt: 1 }}
                            >
                                Afficher tous les congés
                            </Button>
                        </>
                    );
                }}
            />
            {/* Pagination */}
            <TablePagination
                rowsPerPageOptions={[25, 50, 100, { value: -1, label: 'Tout' }]}
                component="div"
                count={filteredContent.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedReportingCongeComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportingCongeComponent);
