import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
    Box,
} from '@mui/material';
import * as DateHelper from '../../helpers/date.helper';
import DayComponent from './day.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import DisplayAvatarComponent from '../assets/infos/display-avatar.component';
import Toggle from '../assets/toggle/toggle';

const HorizontalWeekComponent = (props) => {
    const theme = useTheme();
    const activities = props.activities;
    const employees = props.employees;
    const date = props.date;
    const [expandedState, setExpandedState] = useState(false);
    const [expandedChildState, setExpandedChildState] = useState(false);
    const [displayAvatar, setDisplayAvatar] = useState(false);

    // Media queries pour adapter la taille du container qui recceuille le composant toggle
    const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isBetweenSmAndMd = useMediaQuery('(min-width: 1520px) and (max-width: 1750px)');
    const isBetweenMdAndLg = useMediaQuery('(min-width: 1200px) and (max-width: 1520px)');
    const isBelow1200 = useMediaQuery('(max-width: 1200px)');

    const buildMonthActivities = (date, activities) => {
        return DateHelper.clearMonth(date, activities, props.allJoursFeries);
    };

    const handlePanelChange = (panel) => (event, isExpanded) => {
        event.target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        setExpandedState(isExpanded ? panel : false);
    };

    const handlePanelChildChange = (panel) => (event, isExpanded) => {
        setExpandedChildState(isExpanded ? panel : false);
    };

    const getDetailsDay = (activitiesMonth, employe, isPhone = false) => {
        const lesJours = (week, index) =>
            week.jours.map((jour, indexJour) => (
                <Grid
                    item
                    xs={(activitiesMonth.reduce((acc, curr) => acc + curr.jours.length, 0) + 2) / 2}
                    lg={1}
                    key={'grid_day_manager_' + indexJour}
                    sx={{
                        minHeight: 'auto',
                        borderColor: 'light.main',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        aspectRatio: { xs: '1.6', lg: '1.1' },
                    }}
                >
                    <DayComponent
                        dayItem={jour}
                        status={jour.status}
                        employe={employe}
                        index={index}
                        rerenderParentCallback={props.rerenderParentCallback}
                        openRightPanel={props.openRightPanel}
                        closeRightPanel={props.closeRightPanel}
                        monthCalendar={props.monthCalendar}
                        allActivityTypes={props.allActivityTypes}
                    />
                </Grid>
            ));

        if (isPhone) {
            return activitiesMonth.map((week, index) =>
                week.jours.length > 0 ? (
                    <Accordion
                        sx={{
                            width: 'auto',
                            backgroundColor: 'light.main',
                            color: '#000',
                            border: `1px solid ${theme.palette.divider}`,
                        }}
                        TransitionProps={{ unmountOnExit: true }}
                        expanded={expandedChildState === 'panel' + employe.user_id + '_' + index}
                        onChange={handlePanelChildChange('panel' + employe.user_id + '_' + index)}
                        key={'accordion_child_' + employe.user_id + '_' + index}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}>
                            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                                Semaine {week.numero}
                            </Typography>
                            <Typography sx={{ fontSize: 13, fontStyle: 'italic', ml: 1 }}>
                                {'du ' +
                                    new Date(week.jours[0].date).getDate() +
                                    ' au ' +
                                    new Date(week.jours[week.jours.length - 1].date).getDate()}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                columns={
                                    activitiesMonth.reduce(
                                        (acc, curr) => acc + curr.jours.length,
                                        0,
                                    ) + 2
                                }
                                sx={{ mt: { xs: 1, lg: 0 } }}
                            >
                                {lesJours(week, index)}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ) : null,
            );
        } else {
            return activitiesMonth.map((week, index) => {
                if (week.jours.length > 0) {
                    return lesJours(week, index);
                }
            });
        }
    };

    return employees.length >= 1 ? (
        <>
            {/* Conteneur parent pour le positionnement relatif */}
            <Box sx={{ position: 'relative' }}>
                {/* Toggle en position fixe pour grands écrans, absolue pour très larges écrans et entre 1220px et 1520px */}
                <Box
                    sx={{
                        position: isBelow1200 ? 'sticky' : 'absolute',
                        top: isBelow1200
                            ? 0
                            : isBetweenMdAndLg
                            ? -45
                            : isBetweenSmAndMd
                            ? -50
                            : -60,
                        left: 0,
                        boxShadow: 2,
                        borderRadius: 1,
                        pb: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        width: isBelow1200
                            ? 'auto'
                            : isBetweenMdAndLg
                            ? '70px'
                            : isBetweenSmAndMd
                            ? '80px'
                            : '90px',
                    }}
                >
                    <Typography sx={{ mb: 0.5, fontSize: 11 }}>
                        {displayAvatar ? 'Affichage des avatars' : 'Affichage par noms'}
                    </Typography>
                    <Toggle
                        active={displayAvatar}
                        setActive={setDisplayAvatar}
                        toggleMode={displayAvatar ? 'avatar' : 'name'}
                    />
                </Box>
            </Box>

            {/* Liste des employés */}
            {isDownLg
                ? employees.map((employe, index) => {
                      let employeActivities = activities[employe.user_id];
                      let activitiesMonth = [];

                      activitiesMonth = buildMonthActivities(date, employeActivities || []);

                      return (
                          <Accordion
                              sx={{
                                  width: 'auto',
                                  backgroundColor: 'light.main',
                                  color: 'primary.main',
                                  border: `1px solid ${theme.palette.divider}`,
                              }}
                              TransitionProps={{ unmountOnExit: true }}
                              expanded={expandedState === 'panel' + index}
                              onChange={handlePanelChange('panel' + index)}
                              key={'accordion_index_' + index}
                          >
                              <AccordionSummary
                                  expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                              >
                                  <Grid container alignItems={'center'} spacing={1}>
                                      {displayAvatar ? (
                                          <Grid item>
                                              <DisplayAvatarComponent user={employe} />
                                          </Grid>
                                      ) : (
                                          <Grid item>
                                              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                                                  {employe.nom.toUpperCase() +
                                                      ' ' +
                                                      employe.prenom.toUpperCase()}
                                              </Typography>
                                          </Grid>
                                      )}
                                  </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                  {getDetailsDay(activitiesMonth, employe, true)}
                              </AccordionDetails>
                          </Accordion>
                      );
                  })
                : employees.map((employe, index) => {
                      let employeActivities = activities[employe.user_id];
                      let activitiesMonth = [];

                      activitiesMonth = buildMonthActivities(date, employeActivities || []);
                      return (
                          <Grid
                              container
                              key={'header_employe_' + index}
                              columns={
                                  activitiesMonth.reduce(
                                      (acc, curr) => acc + curr.jours.length,
                                      0,
                                  ) + 2
                              }
                              sx={{ mt: { xs: 1, lg: 0 }, position: 'relative' }}
                          >
                              <Grid item lg={2} sx={{ p: 1 }}>
                                  {displayAvatar ? (
                                      <DisplayAvatarComponent user={employe} overlay={true} />
                                  ) : (
                                      <Typography
                                          sx={{
                                              height: '58px',
                                              fontSize: 13,
                                              fontWeight: 'bold',
                                          }}
                                      >
                                          {employe.nom.toUpperCase() +
                                              ' ' +
                                              employe.prenom.toUpperCase()}
                                      </Typography>
                                  )}
                              </Grid>
                              {getDetailsDay(activitiesMonth, employe)}
                          </Grid>
                      );
                  })}
        </>
    ) : null;
};

export default HorizontalWeekComponent;
