import { Chip } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaidIcon from '@mui/icons-material/Paid';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const renderStatusChip = (status) => {
    switch (status) {
        case 'Brouillon':
            return <Chip label="Brouillon" icon={<EditNoteIcon />} color="default" />;
        case 'À valider':
            return <Chip label="À valider" icon={<AccessTimeIcon />} color="warning" />;
        case 'Évaluée':
            return <Chip label="Évaluée" icon={<CheckCircleOutlineIcon />} color="success" />;
        case 'Approuvée':
            return <Chip label="Approuvée" icon={<CheckCircleOutlineIcon />} color="primary" />;
        case 'Payée':
            return <Chip label="Payée" icon={<PaidIcon />} color="success" />;
        case 'Annulée':
            return <Chip label="Annulée" icon={<CancelIcon />} color="error" />;
        case 'Refusée':
            return <Chip label="Refusée" icon={<ClearIcon />} color="error" />;
        default:
            return (
                <Chip
                    label="Inconnu"
                    icon={<HelpOutlineIcon />}
                    color="disabled"
                    sx={{ fontWeight: 'bold' }}
                />
            );
    }
};
