import axiosConfig from './axiosConfig';

const API_URL = '/authorization/fichiers/';
const cleanParam = (param) => {
    if (!param) return '';

    const replacements = {
        é: 'e',
        è: 'e',
        ê: 'e',
        ë: 'e',
        à: 'a',
        â: 'a',
        ä: 'a',
        î: 'i',
        ï: 'i',
        ô: 'o',
        ö: 'o',
        ù: 'u',
        û: 'u',
        ü: 'u',
        ç: 'c',
        É: 'E',
        È: 'E',
        Ê: 'E',
        Ë: 'E',
        À: 'A',
        Â: 'A',
        Ä: 'A',
        Î: 'I',
        Ï: 'I',
        Ô: 'O',
        Ö: 'O',
        Ù: 'U',
        Û: 'U',
        Ü: 'U',
        Ç: 'C',
    };

    return param
        .split('')
        .map((char) => replacements[char] || char)
        .join('')
        .replace(/[^a-zA-Z0-9]/g, '-');
};

class FichiersService {
    getAll() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    uploadFile(formData) {
        return axiosConfig
            .post('/authorization/uploadFile', formData, {
                headers: {
                    'Content-Type': undefined,
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    uploadFileNDF(formData, info) {
        return axiosConfig
            .post('/authorization/uploadFileNDF', formData, {
                params: info,
                headers: {
                    'Content-Type': undefined,
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    getCategorieNoteDeFrais() {
        return axiosConfig
            .get(API_URL + 'get/categorie')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    insertFichierNDF(objectFichier) {
        return axiosConfig
            .post(API_URL + 'ndf', objectFichier)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    insertFichier(objectFichier) {
        return axiosConfig
            .post(API_URL, objectFichier)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    updateFichiers(objectFichier) {
        return axiosConfig
            .put(API_URL, objectFichier)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    getFichier(fichierId) {
        return axiosConfig
            .get(API_URL + fichierId)
            .then((res) => res.data)
            .catch((err) => ({
                error: err.message,
            }));
    }
    getFichierByUUID(uuid) {
        return axiosConfig
            .get(API_URL + 'UUID/' + uuid)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    getFichierFile(fichierFile) {
        return axiosConfig
            .get('/authorization/getFile/' + fichierFile)
            .then((res) => {
                return URL.createObjectURL(res.data);
            })
            .catch((err) => {
                return { error: err.message };
            });
    }
    getFichierNDF(userName, fileName, periodeDate) {
        const cleanedUserName = cleanParam(userName);
        const cleanedPeriodeDate = cleanParam(periodeDate);
        return axiosConfig
            .get(`/authorization/getFileNDF/${cleanedUserName}/${cleanedPeriodeDate}/${fileName}`)
            .then((res) => {
                return URL.createObjectURL(res.data);
            })
            .catch((err) => ({ error: err.message }));
    }
    getFichiersNDF(periodeID) {
        return axiosConfig
            .get(API_URL + 'ndf/' + periodeID)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    deleteFichier(fichierId) {
        return axiosConfig
            .delete(API_URL + fichierId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    deleteFichierNDF(userName, fileName, periodeDate) {
        const cleanedUserName = cleanParam(userName);
        const cleanedPeriodeDate = cleanParam(periodeDate);
        return axiosConfig
            .delete(
                `${API_URL}note-de-frais/file/${cleanedUserName}/${cleanedPeriodeDate}/${fileName}`,
            )
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    deleteFichierNDFFromBase(fileId) {
        return axiosConfig
            .delete(API_URL + '/ndf/' + fileId)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    getFichiersFromPage(infoPage) {
        return axiosConfig
            .get(API_URL + 'allByInfoPage', { params: infoPage })
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    getMaxPageFichiers(infoPage) {
        return axiosConfig
            .get(API_URL + 'maxPageFichiers', { params: infoPage })
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new FichiersService();
