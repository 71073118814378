// Fonction qui prend en paramètre un tableau de note de frais. Elle renvoie une note de frais selon le statut
export const notesDeFraisEtat = (notesDeFrais) => {
    const notesDeFraisBrouillon = notesDeFrais.filter(
        (noteDeFrais) => noteDeFrais.periode_note_de_frai.brouillon === true,
    );

    const notesDeFraisValide = notesDeFrais.filter(
        (noteDeFrais) => noteDeFrais.periode_note_de_frai.est_valide === true,
    );

    const notesDeFraisEvaluee = notesDeFrais.filter(
        (noteDeFrais) =>
            (noteDeFrais.periode_note_de_frai.brouillon == false) &
            (noteDeFrais.periode_note_de_frai.est_valide == true) &
            (noteDeFrais.periode_note_de_frai.est_evalue == true),
    );

    const notesDeFraisApprouvee = notesDeFrais.filter(
        (noteDeFrais) =>
            (noteDeFrais.periode_note_de_frai.brouillon == false) &
            (noteDeFrais.periode_note_de_frai.est_valide == true) &
            (noteDeFrais.periode_note_de_frai.est_evalue == true) &
            (noteDeFrais.periode_note_de_frai.est_approuve == true),
    );

    const notesDeFraisPayee = notesDeFrais.filter(
        (noteDeFrais) =>
            (noteDeFrais.periode_note_de_frai.brouillon == false) &
            (noteDeFrais.periode_note_de_frai.est_valide == true) &
            (noteDeFrais.periode_note_de_frai.est_evalue == true) &
            (noteDeFrais.periode_note_de_frai.est_approuve == true) &
            (noteDeFrais.periode_note_de_frai.est_paye == true),
    );

    const notesDeFraisAnnulee = notesDeFrais.filter(
        (noteDeFrais) => noteDeFrais.periode_note_de_frai.est_annule == true,
    );

    const notesDeFraisRefusee = notesDeFrais.filter(
        (noteDeFrais) => noteDeFrais.periode_note_de_frai.est_refuse == true,
    );

    return {
        notesDeFraisBrouillon,
        notesDeFraisValide,
        notesDeFraisEvaluee,
        notesDeFraisApprouvee,
        notesDeFraisPayee,
        notesDeFraisAnnulee,
        notesDeFraisRefusee,
    };
};

//Renvoie le statut des notes de frais en lui passant en paramètre une note de frais
export const noteDeFraisEtat = (noteDeFrais) => {
    if (noteDeFrais.periode_note_de_frai.brouillon === true) {
        return 'Brouillon';
    } else if (noteDeFrais.periode_note_de_frai.est_valide === true) {
        return 'Validé';
    } else if (
        (noteDeFrais.periode_note_de_frai.brouillon == false) &
        (noteDeFrais.periode_note_de_frai.est_valide == true) &
        (noteDeFrais.periode_note_de_frai.est_evalue == true)
    ) {
        return 'Évaluée';
    } else if (
        (noteDeFrais.periode_note_de_frai.brouillon == false) &
        (noteDeFrais.periode_note_de_frai.est_valide == true) &
        (noteDeFrais.periode_note_de_frai.est_evalue == true) &
        (noteDeFrais.periode_note_de_frai.est_approuve == true)
    ) {
        return 'Approuvée';
    } else if (
        (noteDeFrais.periode_note_de_frai.brouillon == false) &
        (noteDeFrais.periode_note_de_frai.est_valide == true) &
        (noteDeFrais.periode_note_de_frai.est_evalue == true) &
        (noteDeFrais.periode_note_de_frai.est_approuve == true) &
        (noteDeFrais.periode_note_de_frai.est_paye == true)
    ) {
        return 'Payée';
    } else if (noteDeFrais.periode_note_de_frai.est_annule == true) {
        return 'notesDeFraisAnnulee';
    } else if (noteDeFrais.periode_note_de_frai.est_refuse == true) {
        return 'Refusée';
    }
};

//Renvoie le statut des périodes de note de frais en lui passant en paramètre une période
export const etatPeriodeNoteDeFrais = (periodeNoteDeFrais) => {
    if (periodeNoteDeFrais.brouillon === true) {
        return 'Brouillon';
    } else if (periodeNoteDeFrais.est_valide === true) {
        return 'À valider';
    } else if (periodeNoteDeFrais.est_evalue === true) {
        return 'Évaluée';
    } else if (periodeNoteDeFrais.est_approuve === true) {
        return 'Approuvée';
    } else if (periodeNoteDeFrais.est_paye === true) {
        return 'Payée';
    } else if (periodeNoteDeFrais.est_annule === true) {
        return 'Annulée';
    } else if (periodeNoteDeFrais.est_refuse === true) {
        return 'Refusée';
    }
    return 'Inconnu';
};
