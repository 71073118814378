import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    TextField,
    Button,
    Modal,
    Box,
    Typography,
    Menu,
    MenuItem,
    MenuList,
    Grid,
    Select,
    InputLabel,
    FormControl,
    Pagination,
    Avatar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { toast } from 'react-toastify';
import noteDeFraisService from '../../../services/note_de_frais.service';
import { etatPeriodeNoteDeFrais } from '../../../helpers/note_de_frais.helper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Close } from '@mui/icons-material';
import moment from 'moment/moment';
import { renderStatusChip } from '../../note-de-frais/render-ship-status.component';
import FichierService from '../../../services/fichiers.service';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PeriodPdfDocument } from '../../note-de-frais/generate-pdf-ndf.component';
import fichiersService from '../../../services/fichiers.service';
import FileUploadComponent from '../../admin/fichiers/file-upload.component';
import { StripedTableRow, TableCellHead } from '../../../theming/customTheme';
import userService from '../../../services/user.service';

const EvaluateNoteDeFrais = (props) => {
    const [periodeNoteDeFrais, setPeriodeNoteDeFrais] = useState([]);
    const [filesState, setFilesState] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchUser, setSearchUser] = useState('');
    const [searchDate, setSearchDate] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [selectedPeriode, setSelectedPeriode] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [statusFilter, setStatusFilter] = useState('');
    const [moderateur, setModerateur] = useState(null);

    const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const statusOptions = [
        { value: '', label: 'Tous les statuts' },
        { value: 'Brouillon', label: 'Brouillon' },
        { value: 'En attente', label: 'En attente' },
        { value: 'Approuvée', label: 'Approuvée' },
        { value: 'Refusée', label: 'Refusée' },
        { value: 'Payée', label: 'Payée' },
    ];

    useEffect(() => {
        let data = [...periodeNoteDeFrais];

        if (searchUser) {
            data = data.filter((periode) =>
                (periode.utilisateurs[0].nom + ' ' + periode.utilisateurs[0].prenom)
                    .toLowerCase()
                    .includes(searchUser.toLowerCase()),
            );
        }

        if (searchDate) {
            data = data.filter(
                (periode) =>
                    moment(periode.date_debut).format('YYYY-MM') ===
                    moment(searchDate).format('YYYY-MM'),
            );
        }

        if (statusFilter) {
            data = data.filter((periode) => etatPeriodeNoteDeFrais(periode) === statusFilter);
        }

        if (sortConfig.key) {
            data.sort((a, b) => {
                let valueA, valueB;

                if (sortConfig.key === 'user') {
                    valueA = a.utilisateurs[0].nom.toLowerCase();
                    valueB = b.utilisateurs[0].nom.toLowerCase();
                } else if (sortConfig.key === 'date') {
                    valueA = new Date(a.date_debut);
                    valueB = new Date(b.date_debut);
                }

                if (sortConfig.direction === 'asc') {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        setFilteredData(data);
    }, [searchUser, searchDate, statusFilter, sortConfig, periodeNoteDeFrais]);

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        props.onLoad('Manager - Évaluer les notes de frais');
        fetchPeriodeToEvaluate();
    }, []);

    const DisplayAvatarComponent = ({ user }) => {
        const [avatarSrc, setAvatarSrc] = useState(null);
        const [error, setError] = useState(null);

        useEffect(() => {
            let isMounted = true;
            const fetchAvatar = async () => {
                setError(null);
                try {
                    if (user && user.photo) {
                        const response = await FichierService.getFichierFile(user.photo);
                        if (isMounted) {
                            setAvatarSrc(response);
                        }
                    }
                } catch (error) {
                    console.error("Error lors de la récupération de l'avatar:", error);
                    if (isMounted) {
                        setError("Erreur lors du chargement de l'avatar");
                    }
                }
            };
            fetchAvatar();
            return () => {
                isMounted = false;
            };
        }, [user.photo]);

        if (error) return <Typography color="error">{error}</Typography>;

        return <Avatar src={avatarSrc} />;
    };

    const fetchPeriodeToEvaluate = async () => {
        try {
            const response = await noteDeFraisService.getAllPeriodeByEntity();
            setPeriodeNoteDeFrais(response);
            setFilteredData(response);
        } catch (error) {
            toast.error('Erreur lors du chargement des périodes');
        } finally {
            setLoading(false);
        }
    };

    const fetchFichierSelectedPeriode = async (periode) => {
        try {
            const response = await fichiersService.getFichiersNDF(periode.periode_note_de_frais_id);
            setFilesState(response);
        } catch (error) {
            toast.error('Erreur lors du chargement de la note de frais');
        } finally {
            setLoading(false);
        }
    };

    const fetchModerateur = async (periode) => {
        try {
            if (periode.fk_moderateur) {
                const response = await userService.getUserById(periode.fk_moderateur);
                setModerateur(response);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du modérateur:', error);
        }
    };

    const toggleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const resetFilters = () => {
        setSearchUser('');
        setSearchDate(null);
        setStatusFilter('');
        setSortConfig({ key: null, direction: 'asc' });
    };

    const handleAction = async (actionFunction, periodeId) => {
        try {
            const response = await actionFunction(periodeId, user.entity);
            setAnchorEl(null);

            if (!response.error) {
                toast.success(response.message);
                await fetchPeriodeToEvaluate();
                await updateSelectedPeriode(periodeId);
            } else {
                toast.error(response.error);
            }
        } catch (error) {
            toast.error("Une erreur est survenue lors de l'action");
            console.error(error);
        }
    };

    const updateSelectedPeriode = useCallback(async (periodeId) => {
        try {
            const updatedPeriode = await noteDeFraisService.getPeriodeNoteDeFraisById(periodeId);
            if (updatedPeriode) {
                setSelectedPeriode(updatedPeriode);
            } else {
                console.error('Aucune donnée récupérée pour la période sélectionnée');
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la période sélectionnée:', error);
        }
    }, []);

    const handleCancelPeriod = (periodeId) =>
        handleAction(noteDeFraisService.cancelPeriodeNoteDeFrais, periodeId);
    const handleRefusePeriod = (periodeId) =>
        handleAction(noteDeFraisService.refusePeriode, periodeId);
    const handleAcceptPeriod = (periodeId) =>
        handleAction(noteDeFraisService.acceptPeriode, periodeId);
    const handlePaidPeriod = (periodeId) => handleAction(noteDeFraisService.paidPeriode, periodeId);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = (periode) => {
        setSelectedPeriode(periode);
        fetchModerateur(periode);
        fetchFichierSelectedPeriode(periode);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedPeriode(null);
        setAnchorEl(null);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <Paper style={{ padding: 16 }}>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
                <TextField
                    label="Rechercher un utilisateur"
                    variant="outlined"
                    size="small"
                    value={searchUser}
                    onChange={(e) => setSearchUser(e.target.value)}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Sélectionner une date"
                        views={['year', 'month']}
                        minDate={moment().year(2024)}
                        maxDate={moment().month(1, 'month')}
                        value={searchDate}
                        onChange={(newValue) => setSearchDate(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" size="small" />
                        )}
                    />
                </LocalizationProvider>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>Statut</InputLabel>
                    <Select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        label="Statut"
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="outlined" color="secondary" onClick={resetFilters}>
                    Réinitialiser
                </Button>
            </div>

            <TableContainer>
                {loading ? (
                    <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
                ) : (
                    <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => toggleSort('user')}
                                            align="center"
                                            sx={{
                                                textTransform: 'none',
                                            }}
                                        >
                                            Utilisateur <ArrowDropDownIcon />
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => toggleSort('date')}
                                            align="center"
                                            sx={{
                                                textTransform: 'none',
                                            }}
                                        >
                                            Période <ArrowDropDownIcon />
                                        </Button>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ fontWeight: 'bold', color: 'primary.main' }}
                                    >
                                        Statut
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ fontWeight: 'bold', color: 'primary.main' }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center" sx={{ py: 3 }}>
                                            Aucune période disponible
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((periode, index) => (
                                        <TableRow
                                            key={periode.periode_note_de_frais_id}
                                            sx={{
                                                backgroundColor:
                                                    index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                                            }}
                                        >
                                            <TableCell align="center">
                                                {periode.utilisateurs[0].nom.toUpperCase() +
                                                    ' ' +
                                                    periode.utilisateurs[0].prenom}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(periode.date_debut).format('MMMM YYYY')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderStatusChip(etatPeriodeNoteDeFrais(periode))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleOpenModal(periode)}
                                                    sx={{
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            backgroundColor: '#1976d2',
                                                        },
                                                    }}
                                                >
                                                    <VisibilityIcon sx={{ mr: 1 }} /> Détails
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={2}
                        >
                            <Box flex={1} display="flex" justifyContent="center">
                                <Pagination
                                    count={Math.ceil(periodeNoteDeFrais.length / rowsPerPage)}
                                    page={page}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Box>
                            <Box>
                                <Typography variant="body2" sx={{ mr: 1 }}>
                                    Lignes par page
                                </Typography>
                                <Select
                                    value={rowsPerPage}
                                    onChange={handleRowsPerPageChange}
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: 80 }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </>
                )}
            </TableContainer>

            <Modal
                key={selectedPeriode ? selectedPeriode.periode_note_de_frais_id : 'default'}
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        minWidth: { xs: '90%', sm: 600, md: 1200 },
                        borderRadius: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '2px solid #eee',
                            pb: 2,
                            mb: 3,
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold">
                            Détails de la Période
                        </Typography>
                        <Button onClick={handleCloseModal} sx={{ minWidth: 'auto', p: 1 }}>
                            <Close fontSize="large" />
                        </Button>
                    </Box>

                    {/* Infos période et utilisateur */}
                    <Grid container spacing={2} sx={{ mb: 3 }} justifyContent="space-between">
                        <Grid item xs={12} sm={6}>
                            <Typography align="left">
                                <strong>Période :</strong>{' '}
                                {moment(selectedPeriode ? selectedPeriode.date_fin : new Date())
                                    .format('MMMM YYYY')
                                    .toUpperCase()}
                            </Typography>
                            <Typography align="left">
                                <strong>État :</strong>{' '}
                                {selectedPeriode ? etatPeriodeNoteDeFrais(selectedPeriode) : ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2.5} align="right">
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    {selectedPeriode?.utilisateurs?.length > 0 ? (
                                        <DisplayAvatarComponent
                                            user={selectedPeriode.utilisateurs[0]}
                                        />
                                    ) : (
                                        'Aucun utilisateur'
                                    )}
                                </Grid>
                                <Grid item>
                                    {selectedPeriode?.utilisateurs?.length > 0 ? (
                                        <>
                                            {selectedPeriode.utilisateurs[0].nom.toUpperCase()}{' '}
                                            {selectedPeriode.utilisateurs[0].prenom}
                                        </>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Table des notes de frais */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Notes de frais :
                    </Typography>
                    <TableContainer
                        sx={{ maxHeight: 400, border: '1px solid #ddd', borderRadius: 2 }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {[
                                        'Date',
                                        'Type',
                                        'Description',
                                        'PU.HT',
                                        'Quantité',
                                        'Montant HT',
                                        'TVA',
                                        'Montant TTC',
                                    ].map((header) => (
                                        <TableCellHead
                                            align="center"
                                            key={header}
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {header}
                                        </TableCellHead>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedPeriode?.note_de_frais?.length > 0 ? (
                                    selectedPeriode.note_de_frais.map((note) => (
                                        <StripedTableRow key={note.note_de_frais_id} hover>
                                            <TableCell align="center">
                                                {moment(note.date).format('DD/MM')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {note.type_note_de_frai.nom}
                                            </TableCell>
                                            <TableCell align="center">{note.description}</TableCell>
                                            <TableCell align="center">
                                                {note.prix_unitaire} €
                                            </TableCell>
                                            <TableCell align="center">{note.quantite}</TableCell>
                                            <TableCell align="center">
                                                {note.montant_ht} €
                                            </TableCell>
                                            <TableCell align="center">
                                                {note.tva_valeur} %
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: 'primary.main',
                                                }}
                                            >
                                                {note.montant_ttc} €
                                            </TableCell>
                                        </StripedTableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
                                            Aucune note de frais disponible
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        display="flex"
                        justifyContent="right"
                        mt={3}
                        p={2}
                        sx={{ bgcolor: '#f9f9f9', borderRadius: 2 }}
                    >
                        <Typography variant="subtitle1">
                            <strong>Montant HT :</strong> {selectedPeriode?.montant_ht || 0} €
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 'bold', color: 'primary.main', ml: 3 }}
                        >
                            <strong>Montant TTC :</strong> {selectedPeriode?.montant_ttc || 0} €
                        </Typography>
                    </Box>

                    {selectedPeriode?.date_debut && (
                        <FileUploadComponent
                            username={selectedPeriode.utilisateurs[0].nom}
                            periodeDate={moment(selectedPeriode.date_debut).format('MMMM-YYYY')}
                            isLoading={loading}
                            uploadedFiles={filesState}
                            modeVisualisation={true}
                        />
                    )}

                    {/* Bouton Actions */}
                    <Box display="flex" justifyContent="right" mt={2}>
                        <Button onClick={handleMenuClick} variant="contained">
                            Actions <ArrowDropDownIcon />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuList>
                                {[
                                    {
                                        label: 'Brouillon',
                                        action: handleCancelPeriod,
                                        color: '#1976d2',
                                    },
                                    {
                                        label: 'Refuser',
                                        action: handleRefusePeriod,
                                        color: '#d32f2f',
                                    },
                                    {
                                        label: 'Approuver',
                                        action: handleAcceptPeriod,
                                        color: '#4caf50',
                                    },
                                    {
                                        label: 'Marquer comme payé',
                                        action: handlePaidPeriod,
                                        color: '#1976d2',
                                    },
                                ].map(({ label, action, color }) => (
                                    <MenuItem
                                        key={label}
                                        onClick={() =>
                                            action(selectedPeriode.periode_note_de_frais_id)
                                        }
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                                color: color,
                                            },
                                        }}
                                    >
                                        {label}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        {selectedPeriode &&
                            selectedPeriode.est_paye &&
                            selectedPeriode.note_de_frais.length > 0 && (
                                <PDFDownloadLink
                                    document={
                                        <PeriodPdfDocument
                                            periode={selectedPeriode}
                                            moderateur={moderateur}
                                        />
                                    }
                                    fileName={`periode_${moment(selectedPeriode.date_fin).format(
                                        'YYYY_MM',
                                    )}.pdf`}
                                >
                                    {({ loading }) => (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ ml: 2 }}
                                            disabled={loading}
                                        >
                                            {loading ? 'Chargement...' : 'Télécharger PDF'}
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                            )}
                    </Box>
                </Box>
            </Modal>
        </Paper>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedEvaluateNoteDeFrais = connect(null, mapDispatchToProps)(EvaluateNoteDeFrais);
