import { useState, useEffect } from 'react';
import { setPageTitle } from '../../redux/actions/scopeAction';
import { connect } from 'react-redux';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Button, TextField, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import noteDeFraisService from '../../services/note_de_frais.service';

const CheckPeriodeNoteDeFraisComponent = (props) => {
    const { control, handleSubmit, setValue } = useForm();
    const [selectedDate, setSelectedDate] = useState(moment());
    const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month'));
    const [endOfMonth, setEndOfMonth] = useState(moment().endOf('month'));
    const navigate = useNavigate();

    useEffect(() => {
        props.onLoad('Créer une note de frais');
    }, []);

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        if (newValue) {
            const start = newValue.clone().startOf('month');
            const end = newValue.clone().endOf('month');
            setStartOfMonth(start);
            setEndOfMonth(end);
            setValue('startDate', start);
            setValue('endDate', end);
        }
    };

    const onSubmit = async () => {
        const dateDebut = startOfMonth.format('YYYY-MM-DD');
        const dateFin = endOfMonth.format('YYYY-MM-DD');

        try {
            const isCreated = await noteDeFraisService.checkPeriodeNoteDeFrais({
                date_debut: dateDebut,
                date_fin: dateFin,
            });

            if (!isCreated) {
                const response = await noteDeFraisService.createPeriodeNoteDeFrais({
                    date_debut: dateDebut,
                    date_fin: dateFin,
                });

                if (response.periode) {
                    toast.success('La période a bien été créée');
                    navigate('/note-de-frais/create-note-de-frais', {
                        state: { noteDeFrais: response.periode },
                    });
                } else {
                    toast.error(
                        response.message ||
                            'Erreur lors de la création ou de la liaison de la période.',
                    );
                }
            } else {
                toast.info('La période a déjà été créée');
                if (isCreated.brouillon === true) {
                    navigate(`/note-de-frais/mes-brouillons/update`, {
                        state: { brouillon_periode_id: isCreated.periode_note_de_frais_id },
                    });
                } else {
                    navigate(`/notes-de-frais`);
                }
            }
        } catch (error) {
            toast.error('Une erreur est survenue : ' + error.message);
        }
    };

    return (
        <Box sx={{ height: '30vh' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h4" color="primary" sx={{ m: 4 }}>
                            Sélectionner un mois
                        </Typography>
                    </Grid>

                    <Box sx={{ width: '100%', maxWidth: '400px' }}>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
                                <Controller
                                    name="date"
                                    control={control}
                                    defaultValue={selectedDate}
                                    render={({ field }) => (
                                        <DatePicker
                                            label="Choisir une période"
                                            views={['year', 'month']}
                                            minDate={moment().subtract(12, 'months')}
                                            maxDate={moment().month(1, 'month')}
                                            value={field.value}
                                            onChange={(newValue) => {
                                                handleDateChange(newValue);
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth />
                                            )}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Button type="submit" variant="contained" fullWidth>
                                Valider la période
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedCheckPeriodeNoteDeFrais = connect(
    null,
    mapDispatchToProps,
)(CheckPeriodeNoteDeFraisComponent);
