import axiosConfig from './axiosConfig';

const API_URL = '/authorization/note_de_frais/';

class NoteDeFraisService {
    getAllNoteDeFrais() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getPeriodeNoteDeFraisByUser(userId) {
        return axiosConfig
            .get(API_URL + 'notes-de-frais', { params: { utilisateurId: userId } })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    async getPeriodeNoteDeFraisById(periodeId) {
        return axiosConfig
            .get(API_URL + 'periode/' + periodeId)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    checkPeriodeNoteDeFrais(periodeObject) {
        return axiosConfig
            .get(API_URL + 'check-periode', {
                params: {
                    date_debut: periodeObject.date_debut,
                    date_fin: periodeObject.date_fin,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    cancelPeriodeNoteDeFrais(periodeId, entity) {
        return axiosConfig
            .put(API_URL + `return-edit/${periodeId}`, {
                params: {
                    fk_entity: entity,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    refusePeriode(periodeId, entity) {
        return axiosConfig
            .put(API_URL + `refuse-periode/${periodeId}`, {
                params: {
                    fk_entity: entity,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    acceptPeriode(periodeId, entity) {
        return axiosConfig
            .put(API_URL + `accept-periode/${periodeId}`, {
                params: {
                    fk_entity: entity,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    paidPeriode(periodeId, entity) {
        return axiosConfig
            .put(API_URL + `paid-periode/${periodeId}`, {
                params: {
                    fk_entity: entity,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getPeriodeToEvaluate() {
        return axiosConfig
            .get(API_URL + 'evaluate-periode')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    getAllPeriodeByEntity() {
        return axiosConfig
            .get(API_URL + 'all-periode')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    deleteNoteDefrais(noteDeFraisId, userId) {
        return axiosConfig
            .delete(API_URL + `notes-de-frais/${noteDeFraisId}`, {
                params: { utilisateurId: userId },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    createPeriodeNoteDeFrais(periodeObject) {
        return axiosConfig
            .post(API_URL + 'check-periode', periodeObject)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    getAllNoteDeFraisType() {
        return axiosConfig
            .get(API_URL + 'types')
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: `${error.message}` }));
    }

    getAllTVA() {
        return axiosConfig
            .get(API_URL + 'getAllTVA')
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: `${error.message}` }));
    }

    createNoteDeFrais(noteObject) {
        return axiosConfig
            .post(API_URL, noteObject)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    createTva(valeur) {
        return axiosConfig
            .post(API_URL + 'tva', valeur)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    deleteTva(tvaId) {
        return axiosConfig
            .delete(API_URL + 'tva/' + tvaId)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    updateNoteDeFrais(noteDeFraisId, noteObject) {
        return axiosConfig
            .put(API_URL + 'mes-brouillons/update/' + noteDeFraisId, noteObject)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    updateTva(valeur) {
        return axiosConfig
            .put(API_URL + 'tva', valeur)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
    getPeriodesBrouillonByUser(userId) {
        return axiosConfig
            .get(API_URL + 'mes-brouillons', { params: { utilisateurId: userId } })
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: `${error.message}` }));
    }

    getPeriodeBrouillonByUser(userId, periodeId) {
        return axiosConfig
            .get(API_URL + 'mes-brouillons/update', {
                params: { utilisateurId: userId, periodeId: periodeId },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: `${error.message}` }));
    }

    validePeriodeNoteDeFrais(periodeId) {
        return axiosConfig
            .put(API_URL + 'mes-brouillons/update', { params: { periodeId: periodeId } })
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: `${error.message}` }));
    }
}

export default new NoteDeFraisService();
