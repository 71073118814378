import { useState } from 'react';
/** Connexion au global state pour récupérer le titre */
import { connect } from 'react-redux';
import {
    // NavLink,
    useNavigate,
} from 'react-router-dom';
import { logout, relogin } from '../../redux/actions/authAction';

import {
    Menu,
    IconButton,
    MenuItem,
    Grid,
    Toolbar,
    Typography,
    Button,
    useMediaQuery,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    // Avatar,
} from '@mui/material';

// Icones utilisés
import MenuIcon from '@mui/icons-material/Menu';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockIcon from '@mui/icons-material/Lock';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SyncIcon from '@mui/icons-material/Sync';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DisplayAvatarComponent from '../assets/infos/display-avatar.component';
import SimpleModalComponent from '../assets/modal/simple-modal.component';
import ErrorWarningComponent from '../assets/infos/error-warning.component';

const HeaderComponent = (props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModalState, setOpenModalState] = useState(false);
    const navigate = useNavigate();
    const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        setAnchorEl(null);
        props.logout();
    };
    const handleReLogin = (email) => {
        props.relogin(email).then(() => {
            navigate('/');
            window.location.reload();
        });
    };

    const handleMultiAccount = () => {
        setOpenModalState(true);
    };

    const backPage = () => {
        navigate(-1);
    };

    const options = [
        {
            link: '/account',
            icon: ManageAccountsIcon,
            title: 'Mon profil',
            properties: {},
        },
        {
            link: '/password',
            icon: LockIcon,
            title: 'Modifier le mot de passe',
            properties: {},
        },
        props.user &&
            (props.user.emails.length > 1
                ? {
                      link: null,
                      icon: SyncIcon,
                      title: 'Changer de compte',
                      properties: {
                          onClick: handleMultiAccount,
                      },
                  }
                : null),
        {
            link: '/',
            icon: MeetingRoomIcon,
            title: 'Se déconnecter',
            properties: {
                onClick: handleLogout,
            },
        },
    ];

    return (
        <>
            {props.user && (
                <SimpleModalComponent
                    isOpen={openModalState}
                    customSx={{ maxWidth: { xs: '90%', md: '25%' } }}
                    handleCloseModal={() => setOpenModalState(false)}
                >
                    <List>
                        {props.user.emails.map((email, index) => (
                            <ListItem disablePadding key={'list_emails_' + index}>
                                <ListItemButton onClick={() => handleReLogin(email)}>
                                    <ListItemIcon>
                                        <AccountCircleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={email} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </SimpleModalComponent>
            )}
            <Toolbar key="header_component" sx={{ p: 1 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'}>
                            {props.isLoggedIn && (
                                <Grid item xs={1}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        onClick={props.handleDrawerToggle}
                                    >
                                        {' '}
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={!props.isLoggedIn ? 12 : 5}
                                container
                                justifyContent={!props.isLoggedIn ? 'center' : 'flex-start'}
                            >
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        flexGrow: 1,
                                        textAlign: !props.isLoggedIn ? 'center' : 'left',
                                    }}
                                >
                                    {props.pageTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {props.user && props.user.passwordExpiresSoon && (
                                    <ErrorWarningComponent
                                        redirectTo={{
                                            link: 'https://ipa.neodt.local',
                                            text: 'Changer',
                                        }}
                                    >
                                        Votre mot de passe expire bientôt
                                    </ErrorWarningComponent>
                                )}
                            </Grid>
                            {props.isLoggedIn && (
                                <Grid item xs={3}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        spacing={1}
                                    >
                                        {isUpLg && (
                                            <Grid item>
                                                <Typography>Connecté en tant que </Typography>
                                                <Typography
                                                    color="primary"
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    {props.user.nom.charAt(0).toUpperCase() +
                                                        props.user.nom.slice(1)}{' '}
                                                    {props.user.prenom.charAt(0).toUpperCase() +
                                                        props.user.prenom.slice(1)}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <DisplayAvatarComponent
                                                user={props.user}
                                                pref={props.pref}
                                                onClick={handleMenu}
                                            />
                                            <Menu
                                                id="menu-user"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                anchorPosition={{ left: 1, top: 1 }}
                                                open={!!anchorEl}
                                                onClose={handleClose}
                                            >
                                                {options.map((option, index) => {
                                                    if (option !== null) {
                                                        return (
                                                            <MenuItem
                                                                key={'menu_link' + index}
                                                                {...{
                                                                    onClick: () => {
                                                                        if (
                                                                            option.link ===
                                                                            '/password'
                                                                        ) {
                                                                            window.open(
                                                                                'https://ipa.neodt.local',
                                                                            );
                                                                        } else {
                                                                            navigate(option.link);
                                                                        }
                                                                    },
                                                                    ...option.properties,
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <option.icon />
                                                                </ListItemIcon>
                                                                <ListItemText>
                                                                    {option.title}
                                                                </ListItemText>
                                                            </MenuItem>
                                                        );
                                                    }
                                                })}
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {props.isLoggedIn && (
                        <Grid item xs={12}>
                            <Button
                                onClick={backPage}
                                variant="text"
                                size="small"
                                sx={{ width: '5%', justifyContent: 'flex-start' }}
                            >
                                Retour
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Toolbar>
        </>
    );
};

// permet de récupérer des informations du state et les rend sous forme de props au component
const mapStateToProps = (state) => {
    if (state.authReducer.isLoggedIn) {
        return {
            pageTitle: state.scopeReducer.pageTitle,
            isLoggedIn: state.authReducer.isLoggedIn,
            user: state.authReducer.user,
            pref: state.prefReducer,
        };
    }
    return {
        pageTitle: state.scopeReducer.pageTitle,
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    relogin: (email) => dispatch(relogin(email)),
});

export const ConnectedHeaderComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderComponent);
