import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPageTitle } from '../../../redux/actions/scopeAction';
import newsService from '../../../services/news.service';
import ResultFormComponent from '../../assets/container/resultForm.component';
import WaitingScreenComponent from '../../assets/waiting-screen.component';
import FormNewsComponent from './form-news.component';

const UpdateNewsComponent = (props) => {
    const location = useLocation();
    const newsId = location.state.objectId;

    const [newsState, setNewsState] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [submittedState, setSubmittedState] = useState(false);
    const [messageState, setMessageState] = useState('');
    const [imageState, setImageState] = useState('');

    useEffect(() => {
        props.onLoad('Administrateur - Modifier une news');

        newsService.getNews(newsId).then((laNews) => {
            if (!laNews.error) {
                const imageSrcReq = newsService.getImage(laNews.imageTitle);
                Promise.all([imageSrcReq]).then(([imageSrc]) => {
                    if (!imageSrc.error) {
                        setImageState(imageSrc);
                        setNewsState(laNews);
                    }
                });
            }
        });
    }, []);

    const handleDeleteNews = (idNews) => {
        setLoadingState(true);
        setSubmittedState(true);
        setSuccessState(false);

        newsService.deleteNews(idNews).then((res) => {
            if (res.error) {
                setLoadingState(false);
                setSuccessState(false);
                setMessageState(res.error);
            } else {
                setLoadingState(false);
                setSuccessState(true);
                setMessageState(res.message);
            }
        });
    };

    const handleUpdateNews = (dataForm) => {
        setLoadingState(1);
        setSubmittedState(true);
        setSuccessState(false);

        // On crée une promesse pour upload l'image s'il y en a une.
        // Pourquoi une promesse ? En fait, dans l'éventualité qu'il faille upload une image, il faut pouvoir attendre que l'upload soit fait pour continuer.
        // Sauf que s'il n'y a pas d'upload à faire, on a pas besoin d'attendre pour faire l'update.
        // Il en résulte que si on veut éviter de faire de la duplication de code, on crée une promesse.
        // Comme ça, qu'importe s'il faut upload ou non, on fera toujours un .then ce qui permet d'éviter de la duplication de code.
        let promiseUpload = new Promise((resolve, reject) => {
            if (dataForm.inputImage !== -1) {
                const formData = new FormData();
                formData.append('file', dataForm.inputImage);
                newsService.uploadFile(formData).then((res) => {
                    if (res.error) {
                        reject('PAS OK');
                    } else {
                        resolve(res);
                    }
                });
            } else {
                resolve({ fichier: -1 });
            }
        });

        promiseUpload
            .then((res) => {
                setLoadingState(50);
                newsService
                    .updateNews({
                        news_id: newsState.news_id,
                        title: dataForm.inputTitle,
                        imageTitle: res.fichier.filename,
                        content: dataForm.inputContent,
                        fk_category: dataForm.inputCategory.value,
                        is_pinned: dataForm.checkPin,
                        check_entity: !!dataForm.checkEntity,
                    })
                    .then((res) => {
                        if (res.error) {
                            setLoadingState(100);
                            setSuccessState(false);
                            setMessageState(res.error);
                        } else {
                            setLoadingState(100);
                            setSuccessState(true);
                            setMessageState(res.message);
                        }
                    });
            })
            .catch((err) => {
                setLoadingState(100);
                setSuccessState(false);
                setMessageState(err.error);
            });
    };

    if (newsState !== null && imageState !== null) {
        return (
            <ResultFormComponent
                loading={loadingState}
                success={successState}
                submitted={submittedState}
                message={messageState}
                link={'/admin/list-news'}
            >
                <FormNewsComponent
                    handleForm={handleUpdateNews}
                    handleDelete={handleDeleteNews}
                    id={newsState.news_id}
                    uuid={newsState.uuid}
                    isUpdate={true}
                    title={newsState.title}
                    image={imageState}
                    category={{
                        label: newsState.fk_category.name,
                        value: newsState.fk_category.category_news_id,
                    }}
                    is_pinned={newsState.is_pinned}
                    content={newsState.content}
                />
            </ResultFormComponent>
        );
    } else {
        return <WaitingScreenComponent />;
    }
};

// Lie notre composant aux action et dispatch
const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdateNewsComponent = connect(null, mapDispatchToProps)(UpdateNewsComponent);
