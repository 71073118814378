import { Box, Grid } from '@mui/material';
import PortalButtonComponent from '../../assets/portal-button.component';
import { CreditScore, CalendarToday } from '@mui/icons-material/';

const ManagerPortalNDFComponent = (props) => {
    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2, width: '100%' }}>
                <PortalButtonComponent
                    title="Évaluer les notes de frais"
                    icon={CreditScore}
                    color="#84C3BE"
                    link="manager/evaluate-note-de-frais"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Gérer la TVA "
                    icon={CalendarToday}
                    color="#641C34"
                    link="manager/tva"
                    role={props.role}
                />
            </Grid>
        </Box>
    );
};

export default ManagerPortalNDFComponent;
