import {
    React,
    useState,
    useEffect,
    // useRef
} from 'react';

import { Route, Routes } from 'react-router-dom';
// History pour redirects
import { history } from '../../helpers/history.helper';
import { useLocation } from 'react-router-dom';

// Les Routes en question
import { ConnectedHeaderComponent } from '../header/header.component';
import { ConnectedPanleftComponent } from '../panleft/panleft.component';
import { ConnectedPersonalTimeComponent } from '../personalTime/personal-time.component';
import { ConnectedAdminPortalComponent } from '../portal/admin-portal.component';
import { ConnectedManagerPortalComponent } from '../portal/manager-portal.component';
import { ConnectedReportingComponent } from '../reporting/reporting.component';
import { ConnectedManagerProjetComponent } from '../manager/managerProjet/managerProjet.component';
import { ConnectedManagerEquipeComponent } from '../manager/managerEquipe/managerEquipe.component';
import { ConnectedEvaluateCongeComponent } from '../manager/managerConge/evaluateConge/evaluate-conge.component';
import { ConnectedAccountComponent } from '../account/account.component';
import { ConnectedPasswordFormComponent } from '../account/password-form.component';
import { ConnectedUsersComponent } from '../admin/users/users.component.js';
import { ConnectedCreateUserComponent } from '../admin/users/create-user.component';
import { ConnectedAdminProjetComponent } from '../projet/adminProjet.component';
import { ConnectedProjetComponent } from '../projet/projet.component';
import { ConnectedAdminUserComponent } from '../admin/users/admin-user.component';
import { ConnectedClientsComponent } from '../admin/clients/clients.component';
import { ConnectedCreateProjetComponent } from '../projet/createProjet.component';
import { ConnectedUpdateUserComponent } from '../admin/users/update-user.component';
import { ConnectedUpdateProjetComponent } from '../projet/update-projet.component';
import { ConnectedManagerUserComponent } from '../manager/managerEquipe/managerUser.component';
import { ConnectedRegionsComponent } from '../admin/regions/regions.component';
import { ConnectedUpdateClientComponent } from '../admin/clients/update-client.component';
import { ConnectedCreateRegionComponent } from '../admin/regions/create-region.component';
import { ConnectedUpdateRegionComponent } from '../admin/regions/update-region.component';
import { ConnectedCreateClientComponent } from '../admin/clients/create-client-page.component';
import { ConnectedUpdateFonctionComponent } from '../admin/fonctions/update-fonction.component';
import { ConnectedFonctionComponent } from '../admin/fonctions/fonctions.component';
import { ConnectedBusinessUnitComponent } from '../admin/business_units/business_units.component';
import { ConnectedUpdateBusinessUnitComponent } from '../admin/business_units/update-business_unit.component';
import { ConnectedCreateBusinessUnitComponent } from '../admin/business_units/create-business_unit.component';
import { ConnectedCreateFonctionComponent } from '../admin/fonctions/create-fonction.component';
import { ConnectedPlanningManagerComponent } from '../planning/planning-manager.component';
import { ConnectedLegendPlanningManagerComponent } from '../admin/planning/legend.component';
import { ConnectedCongeComponent } from '../conge/conge.component';
import { ConnectedGestionComponent } from '../admin/gestion/gestion-administration.component';
import { ConnectedReportingCongeComponent } from '../manager/managerConge/reportingConge/reporting-conge.component.js';
import { ConnectedNoteDeFraisMenu } from '../note-de-frais/menu-note-de-frais.page.js';
import { ConnectedDisplayPeriodeBrouillon } from '../note-de-frais/displayPeriodeBrouillon.component.js';
import { ConnectedUpdatePeriodeNoteDeFrais } from '../note-de-frais/updatePeriodeBrouillon.page.js';
import { ConnectedEvaluateNoteDeFrais } from '../manager/managerNoteDeFrais/evaluate-note-de-frais.component.js';
import { ConnectedManageTva } from '../manager/managerNoteDeFrais/tva/manage-tva.component.js';
import { ConnectedCheckPeriodeNoteDeFrais } from '../note-de-frais/checkPeriodeNoteDefrais.component.js';
import { ConnectedNotesDeFraisDisplay } from '../note-de-frais/display-note-de-frais.component.js';
import NoMatchComponent from '../nomatch.component';
import NotLoggedInComponent from '../notloggedin.component';

// Surveillance de login
import { ConnectedProtectedRouteComponent as ProtectedRoute } from '../assets/routing/protected-route.component';

import { Box, useMediaQuery, AppBar, useTheme } from '@mui/material';
import { ConnectedListNewsComponent } from '../admin/news/list-news.component';
import { ConnectedCreateNewsComponent } from '../admin/news/create-news.component';
import { ConnectedUpdateNewsComponent } from '../admin/news/update-news.component';
import { ConnectedCreateCategoryNewsComponent } from '../admin/news/category_news/create-category-news.component';
import { ConnectedUpdateCategoryComponent } from '../admin/news/category_news/update-category-news.component';
import { ConnectedAccueilComponent } from '../accueil/accueil.component';
import { ConnectedListFichiersComponent } from '../admin/fichiers/list-fichiers.component';
import { ConnectedCreateFichiersComponent } from '../admin/fichiers/create-fichiers.component';
import { ConnectedUpdateFichiersComponent } from '../admin/fichiers/update-fichiers.component';
import { ConnectedCreateCategoryFichiersComponent } from '../admin/fichiers/category_fichiers/create-category-fichiers.component';
import { ConnectedUpdateCategoryFichiersComponent } from '../admin/fichiers/category_fichiers/update-category-fichiers.component';
import notificationService from '../../services/notification.service';
import { ServiceWorkerUpdateListener } from '../../serviceWorkerUpdateListener';
import UpdateWaiting from '../assets/infos/update-waiting.component';
import OfflineWarningComponent from '../assets/infos/offline-warning.component';
import { ConnectedSoldesComponent } from '../manager/managerConge/soldes/soldes.component';
import { ToastContainer } from 'react-toastify';
import { ConnectedLoginComponent } from '../login/login.component';
import { ConnectedCreateNoteDeFrais } from '../note-de-frais/createNoteDeFrais.component.js';

export const App = () => {
    const theme = useTheme();
    const location = useLocation();
    const isLoginPage = location.pathname === '/';
    let drawerWidth = 280;
    // const { window } = props;
    const [mobileOpenState, setMobileOpenState] = useState(null);
    const [drawerWidthState, setDrawerWidthState] = useState(null);
    const [loggedState, setLoggedState] = useState(!!JSON.parse(localStorage.getItem('user')));

    // States relatif au service-worker
    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState({});

    const [doneRender, setDoneRender] = useState(0);

    // Variable booléenne retournant vrai si l'écran est en dessous de la taille xs, false sinon.
    const checkXS = useMediaQuery(theme.breakpoints.down('sm'));
    const env = process.env.REACT_APP_ENV || 'development';

    useEffect(() => {
        if (loggedState && env !== 'development') {
            notificationService.createNotificationSubscription().then((res) => {
                if (!res.error) {
                    notificationService.postSubscription(res).then((res) => {
                        console.log('Souscription aux notifications terminée.' + res);
                    });
                }
            });
        }
    }, [loggedState]);

    const handleDrawerToggle = (_, valeur = null) => {
        if (valeur === null) {
            valeur = !mobileOpenState;
        }
        setMobileOpenState(valeur);
        if (valeur) {
            setDrawerWidthState(drawerWidth);
        } else {
            setDrawerWidthState(0);
        }
    };

    useEffect(() => {
        if (checkXS) {
            handleDrawerToggle(null, false);
        } else {
            handleDrawerToggle(null, true);
        }
    }, [checkXS]);

    useEffect(() => {
        setDoneRender(1);
        if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development') {
            let listener = new ServiceWorkerUpdateListener();
            setSwListener(listener);
            listener.onupdateinstalling = (installingEvent) => {
                console.log('SW installed', installingEvent);
            };
            listener.onupdatewaiting = (waitingEvent) => {
                console.log('new update waiting', waitingEvent);
                setUpdateWaiting(true);
            };
            listener.onupdateready = (event) => {
                console.log('updateready event', event);
                window.location.reload();
            };
            navigator.serviceWorker.getRegistration().then((reg) => {
                listener.addRegistration(reg);
                setRegistration(reg);
            });

            return () => listener.removeEventListener();
        }
    }, []);

    const handleUpdate = () => {
        swListener.skipWaiting(registration.waiting);
    };

    // Condition permettant de vérifier que tout est chargé correctement avant d'initialiser les composants enfants.
    if (doneRender) {
        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        minHeight: '100%',
                    }}
                >
                    <AppBar
                        position="fixed"
                        color="light"
                        sx={{
                            width: isLoginPage
                                ? '100%'
                                : { sm: `calc(100% - ${drawerWidthState}px - 15px)` },
                            height: { xs: '110px', md: '100px' },
                            m: '5px',
                            borderRadius: '0px',
                        }}
                    >
                        <ConnectedHeaderComponent
                            handleDrawerToggle={handleDrawerToggle}
                            drawerWidthState={drawerWidthState}
                        />
                    </AppBar>
                    {!isLoginPage && (
                        <Box
                            component="nav"
                            sx={{ width: { sm: drawerWidthState }, flexShrink: { sm: 0 } }}
                        >
                            <ConnectedPanleftComponent
                                allProps={{
                                    variant: 'persistent',
                                    sx: {
                                        display: { xs: 'none', sm: 'block' },
                                        '& .MuiDrawer-paper': {
                                            boxSizing: 'border-box',
                                            width: drawerWidthState,
                                            height: 'calc(100% - 10px)',
                                        },
                                    },
                                    open: mobileOpenState === null ? true : mobileOpenState,
                                }}
                            />
                            <ConnectedPanleftComponent
                                window={window}
                                allProps={{
                                    variant: 'temporary',
                                    open: mobileOpenState === null ? true : mobileOpenState,
                                    onClose: (e) => handleDrawerToggle(e, false),
                                    ModalProps: { keepMounted: true },
                                    sx: {
                                        display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': {
                                            boxSizing: 'border-box',
                                            width: drawerWidthState,
                                            height: 'calc(100% - 10px)',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    )}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: { sm: `calc(100% - ${drawerWidthState}px)` },
                            minHeight: '100%',
                            overflow: 'auto',
                            mt: { xs: '110px', md: '100px' },
                        }}
                    >
                        <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate}>
                            <OfflineWarningComponent />

                            <Routes history={history}>
                                <Route
                                    path="/"
                                    exact
                                    element={
                                        <ConnectedLoginComponent
                                            setLogin={(val) => setLoggedState(val)}
                                        />
                                    }
                                />

                                <Route
                                    path="/my-times"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedPersonalTimeComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/planning-manager"
                                    element={
                                        <ProtectedRoute role={'commerce'}>
                                            <ConnectedPlanningManagerComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/portal-manager"
                                    element={
                                        <ProtectedRoute role={'commerce'}>
                                            <ConnectedManagerPortalComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/reporting"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedReportingComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/projects"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedManagerProjetComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/projet"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedProjetComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/manager/team"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedManagerEquipeComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/manager/user"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedManagerUserComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/manager/evaluate-conge"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedEvaluateCongeComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/manager/reporting-conges"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedReportingCongeComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/manager/soldes"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedSoldesComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/user"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedAdminUserComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/portal-admin"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedAdminPortalComponent role={'admin'} />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/users"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUsersComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/clients"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedClientsComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-client"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateClientComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/update-client/"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateClientComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/regions"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedRegionsComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-region"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateRegionComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/update-region"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateRegionComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/fonctions"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedFonctionComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-fonction"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateFonctionComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/update-fonction"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateFonctionComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/business-units"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedBusinessUnitComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-business-unit"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateBusinessUnitComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/update-business-unit"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateBusinessUnitComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-user"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateUserComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/update-user"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateUserComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/account"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedAccountComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/change-password"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedPasswordFormComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/conges"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedCongeComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/note-de-frais"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedNoteDeFraisMenu />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/notes-de-frais"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedNotesDeFraisDisplay />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="note-de-frais/mes-brouillons"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedDisplayPeriodeBrouillon />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="note-de-frais/mes-brouillons/update"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedUpdatePeriodeNoteDeFrais />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="manager/evaluate-note-de-frais"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedEvaluateNoteDeFrais />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="manager/tva"
                                    element={
                                        <ProtectedRoute role={'manager'}>
                                            <ConnectedManageTva />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/note-de-frais/check-periode"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedCheckPeriodeNoteDeFrais />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/note-de-frais/create-note-de-frais"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedCreateNoteDeFrais />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/projet-all"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedAdminProjetComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-projet"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateProjetComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/legend"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedLegendPlanningManagerComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/update-projet"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateProjetComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/list-news"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedListNewsComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/create-news"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateNewsComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/update-news"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateNewsComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/create-category-news"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateCategoryNewsComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/update-category-news"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateCategoryComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="home"
                                    element={
                                        <ProtectedRoute role={'employe'}>
                                            <ConnectedAccueilComponent role={'employe'} />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/list-fichiers"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedListFichiersComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/create-fichiers"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateFichiersComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/update-fichiers"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateFichiersComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/create-category-fichiers"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedCreateCategoryFichiersComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/update-category-fichiers"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedUpdateCategoryFichiersComponent />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="admin/gestion-neocra"
                                    element={
                                        <ProtectedRoute role={'admin'}>
                                            <ConnectedGestionComponent />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route path="/security" element={<NotLoggedInComponent />} />
                                <Route element={<NoMatchComponent />} />
                            </Routes>
                        </UpdateWaiting>
                    </Box>
                </Box>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    theme={theme.palette.mode === 'light' ? 'colored' : 'dark'}
                />
            </>
        );
    } else {
        return <>Chargement...</>;
    }
};

export default App;
