// import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/scopeAction';
import noteDeFraisService from '../../services/note_de_frais.service';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import FormNoteDeFraisComponent from './form-note-de-frais.component';

const UpdatePeriodeBrouillon = (props) => {
    const location = useLocation();
    const periodeBrouillonId = location.state?.brouillon_periode_id;
    const user = JSON.parse(localStorage.getItem('user'));
    const [errorState, setErrorState] = useState(false);
    const [periodeBrouillonState, setPeriodeBrouillonState] = useState();

    const fecthPeriodeBrouillon = () => {
        if (!periodeBrouillonId) {
            setErrorState('ID de période de brouillon manquant.');
            return;
        }
        noteDeFraisService
            .getPeriodeBrouillonByUser(user.user_id, periodeBrouillonId)
            .then((response) => {
                if (response.error) {
                    setErrorState('Une erreur est survenue : ', response.error);
                } else {
                    setPeriodeBrouillonState(response);
                }
            });
    };

    useEffect(() => {
        props.onLoad('Mes notes de frais - Modifier une période');
        fecthPeriodeBrouillon();
    }, []);

    useEffect(() => {
        toast.error(errorState);
    }, [errorState]);

    return (
        <>
            <h1>
                {errorState || !periodeBrouillonId ? (
                    <section className="body-main-container flex-container column-container">
                        <div className="padded-container error-text">
                            <ToastContainer
                                position="bottom-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                closeOnClick
                                pauseOnHover
                            />
                            {errorState}
                        </div>
                    </section>
                ) : (
                    <FormNoteDeFraisComponent periodeBrouillon={periodeBrouillonState} />
                )}
            </h1>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (title) => dispatch(setPageTitle(title)),
});

export const ConnectedUpdatePeriodeNoteDeFrais = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdatePeriodeBrouillon);
